import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { getPermissions, getUserDetails, UserState } from '../state/user/user.reducer';
import { switchMap, take } from 'rxjs/operators';
import { MiscellaneousPermissions } from '../permisions/enums/miscellaneous-permissions';
import { Modules } from '../permisions/enums/modules';
import { SettingsPermissions } from '../permisions/enums/settings-permissions';

@Injectable({
	providedIn: 'root'
})
export class UserBusinessOwnerGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.authService.isLoggedIn()) {
			return this.userStore.pipe(
				select(getUserDetails),
				take(1),
				switchMap(userDetails => {
					const fbId = userDetails.FacebookBusinessOwnerId;

					const googleBO = JSON.parse(localStorage.getItem('installedPlatorm'))?.find(value => {
						return value === 'Google';
					});
					if ((!fbId || fbId === '') && !googleBO) {
						this.authService.setRoute$.next(next.routeConfig.path);
						this.router.navigate(['/auth-landing']);
						return of(false);
					}
					return of(true);
				})
			);
		}

		return of(false);
	}
}
