<div class="form-input" id="input-form-component">
	<kendo-formfield>
		<kendo-textbox
			[placeholder]="placeHolderTextBox"
			[disabled]="disabled"
			[readonly]="readOnly"
			[formControl]="inputControl"
			[showSuccessIcon]="inputControl.valid"
			[showErrorIcon]="'initial'"
			[style.width]="'100%'"
			[maxlength]="maxLength"
		></kendo-textbox>
		<kendo-formerror>{{ errorMessage }}</kendo-formerror>
	</kendo-formfield>
</div>
