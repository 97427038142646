<div class="grid-section" *ngIf="!hasRedirectParam">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-gap-left"></div>
		<div class="grid-section-two-inner container">
			<div class="outside-sign-in" *ngIf="!loading">
				<div class="filed-logo">
					<img alt="LOLLY" src="assets/logo/lolly-logo.svg" />
				</div>
				<div class="text-description">
					<h2>Log into your account</h2>
					<p>
						Don't have an account?
						<a (click)="onSubscribeToAnOffer()" class="link signup">Subscribe to offer</a>
					</p>
				</div>

				<form class="login-form" *ngIf="!isAccountSignedIn" [formGroup]="signInForm">
					<div class="margin-bottom-spacing">
						<outline-tb [control]="emailFormControl" label="Enter Email" [validatorMessages]="validationMessages" class="input-box"></outline-tb>
					</div>
					<div class="margin-bottom-spacing-ps">
						<passwordoutline-tb (enterPress)="onSubmit()" name="password" [control]="passwordFormControl" class="input-box"></passwordoutline-tb>
					</div>

					<div class="margin-bottom-spacing-ps">
						<a (click)="onForgotPasswordClick()" class="link password_forgot">Forgot password?</a>
					</div>
					<div>
						<btn01 (onClick)="onSubmit()">Log In</btn01>
					</div>
				</form>
			</div>
			<div class="loading" *ngIf="loading">
				<lolly-spinning-loader></lolly-spinning-loader>
			</div>
		</div>
		<div class="grid-gap-right"></div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
