import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-input-field',
	templateUrl: './input-field.component.html',
	styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {
	@Input('placeHolderTextBox') public placeHolderTextBox: string = 'Enter text here';
	@Input('inputControl') public inputControl: FormControl;
	@Input() public disabled: boolean = false;
	@Input('readOnly') public readOnly: boolean = false;
	@Input('errorMessage') public errorMessage: string = 'Enter text';
	@Input('maxLength') public maxLength: number = 180;
	private unsubscriber$ = new Subject<void>();

	constructor() {}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
