import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { UserDetailModel } from '../../_models/login.model';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { NewSignUpSubscribe } from '../../sign-up/subscribe.interface';
import { sleep } from '../utils/signup-utils';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { Ntf01Service } from '@filed-com/filed-lib';

@Component({
	selector: 'app-email-verification',
	templateUrl: './email-verification.component.html',
	styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public emailControl: FormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = true;
	public error: boolean = false;
	public subscribeData: NewSignUpSubscribe;

	public registrationDetail: UserDetailModel;
	emailSent: boolean;

	constructor(
		private router: Router,
		private store: Store<AuthenticationState>,
		private sharedStore: Store<SharedState>,
		private backOfficeService: BackOfficeService,
		private toastNotificationService: Ntf01Service
	) {}

	public ngOnInit(): void {
		this.getRegistrationDetails();
		this.processRegistrationDetail();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$;
	}

	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.registrationDetail = detail;
			}
		});
	}

	public processRegistrationDetail(): void {
		if (!this.registrationDetail.plan) {
			this.toastNotificationService.sendErrorToast('Invalid tarrif plan');
			this.router.navigate(['/authentication']);
		} else if (!this.registrationDetail.email) {
			this.toastNotificationService.sendErrorToast('User Email is invalid');
			this.router.navigate(['/authentication/register-email']);
		} else if (!this.registrationDetail.name) {
			this.toastNotificationService.sendErrorToast('User Full name is invalid');
			this.router.navigate(['/authentication/personal-detail']);
		} else if (!this.registrationDetail.phone) {
			this.toastNotificationService.sendErrorToast('User Phone number is invalid');
			this.router.navigate(['/authentication/personal-detail']);
		} else {
			this.submitting = true;
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			let names = this.registrationDetail.name.split(' ');
			let dialingCode = JSON.parse(this.registrationDetail.dialCode);
			this.subscribeData = {
				offerId: this.registrationDetail.plan,
				firstName: this.registrationDetail.name,
				lastName: this.registrationDetail.lastname,
				companyName: this.registrationDetail.companyName,
				phoneNumber: dialingCode.dialcode + this.registrationDetail.phone,
				email: this.registrationDetail.email,
				duration: this.registrationDetail.duration
			};

			this.error = false;

			this.backOfficeService
				.subscribeToOfer(this.subscribeData)
				.toPromise()
				.catch(error => {
					this.error = true;
					if (error && error.error && error.error[0] && error.error[0].description && error.error[0].description.length) {
						this.toastNotificationService.sendErrorToast(error.error[0].description.split(':')[0]);
					} else if (
						(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__Contact__ExistsWithSameCriteria') ||
						(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__Contact_Hubspot__ExistsWithSameCriteria')
					) {
						this.toastNotificationService.sendErrorToast('A user already exists with this email');
					} else {
						this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
					}

					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
					return;
				})
				.then(() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
					if (!this.error) {
						this.emailSent = true;
						this.toastNotificationService.sendSuccessToast('Email was successfully sent.');
					}
				});
		}
	}

	public resendVerificationEmail(): void {
		this.backOfficeService
			.resendActivationEmail(this.registrationDetail.email)
			.toPromise()
			.catch(error => {
				this.error = true;
				if (error && error.error && error.error[0] && error.error[0].description && error.error[0].description.length) {
					this.toastNotificationService.sendErrorToast(error.error[0].description.split(':')[0]);
				} else if (
					(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__ResendFirstPasswordEmail__IsNotAllowed') ||
					(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__Contact_Hubspot__ExistsWithSameCriteria')
				) {
					this.toastNotificationService.sendErrorToast('A user already exists with this email');
				} else {
					this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
				}

				this.submitting = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
				return;
			})
			.then(() => {
				this.submitting = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
				if (!this.error) {
					this.emailSent = true;
					this.toastNotificationService.sendSuccessToast('Email was successfully sent.');
				}
			});
	}

	public openGmailAccount(): void {
		window.location.href = `https://mail.google.com/mail/u/?authuser=${this.registrationDetail.email}`;
	}

	public openOutlookAccount(): void {
		window.location.href = `https://outlook.office365.com/mail/`;
	}
}
