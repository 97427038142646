import { Component, Injector, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { getUserDetails } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { CardDetailsInterface, TarrifPlansInterface } from '../../sign-up/subscribe.interface';
import { LogIn } from '../../state/authentication.action';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { LogInModel } from '../../_models/login.model';
@Component({
	selector: 'app-payment-confirmation',
	templateUrl: './payment-confirmation.component.html',
	styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmation implements OnInit, OnDestroy {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public planDetails: any;
	public cardDetails: FormGroup;
	public expirationDate: FormControl;
	public cardNumber: FormControl;

	public submitting: boolean;

	public currentRoute: string;
	public title: string;
	public subtitle: string;
	public errorMessage: string;
	public buttonStatus: boolean = false;
	@ViewChild('ccNumber') ccNumberField!: ElementRef;

	protected sharedStore: Store<SharedState>;
	constructor(
		private injector: Injector,
		private formBuilder: FormBuilder,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private router: Router,
		private authStore: Store<AuthenticationState>,
		private backOfficeService: BackOfficeService,
		public activeRoute: ActivatedRoute
	) {
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
	}

	ngOnInit(): void {
		this.createForm();
		this.getTarrifDetails();
		this.onFormNameChange();
		this.activeRoute.queryParams.pipe(take(1)).subscribe(params => {
			if (params['bexid'] == undefined) {
				this.buttonStatus = true;
				this.title = 'Thank you for joining us!';
				this.subtitle = 'You have been sent an email confirmation for the payment.';
			} else {
				this.checkPaymentStatus(params['bexid']);
			}
		});
	}

	public logout(): void {
		localStorage.clear();
		this.router.navigate(['/authentication']);
	}

	public checkPaymentStatus(id: number): void {
		this.billingService
			.checkPaymentStatus(id)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.buttonStatus = true;
					this.title = 'Thank you for joining us!';
					this.subtitle = 'You have been sent an email confirmation for the payment.';
				},
				error => {
					this.buttonStatus = false;
					if (error.error != null) {
						switch (error.error[0].code) {
							case 'Filed__Domain__NA__Contact_NoActiveSubscriptions__IsInvalid':
								this.title = 'Payment Failed!';
								this.subtitle = 'Please logout and try again.';
								break;
							case 'Filed__Domain__NA__Payment_PENDING':
								this.title = 'Payment Failed!';
								this.subtitle = 'Please logout and try again after some time.';
								break;
							case 'Filed__Domain__NA__Payment_INPROGRESS':
								this.title = 'Thank you for joining us!';
								this.subtitle = 'Your payment is in progress please check your email for confirmation';
								break;
							default:
								this.title = 'Payment Failed!';
								this.subtitle = 'Please logout and try again.';
								break;
						}
					} else {
						this.title = 'Payment Failed!';
						this.subtitle = 'Please logout and try again.';
					}
				}
			);
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();

		this.backOfficeService.signUpGetStartedActive$.next('idle');
	}
	public noWhitespaceValidator(control: FormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return { whitespace: true };
	}
	public navigateToLunch(): void {
		this.backOfficeService.signUpGetStartedActive$.next('active');
		this.authStore.dispatch(new LogIn());

		this.backOfficeService.signUpGetStartedActive$.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			this.authStore
				.select(getUserDetails)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(user => {
					if (user && value === 'running') {
						this.router.navigate(['/authentication/launch']);
						this.backOfficeService.signUpGetStartedActive$.next('idle');
					}
				});
		});
	}
	get getPlanAmount(): string {
		let cur = this.planDetails?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.planDetails?.currency || 'USD'
		}).format(this.planDetails?.amount || 0);
		return cur + ' ' + amount;
	}
	public createForm(): void {
		this.cardDetails = this.formBuilder.group({
			cardName: new FormControl('', Validators.required),
			cardNumber: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[0-9])[ 0-9]+$'), Validators.minLength(17)]),
			securityCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(3)]),
			expirationMonth: new FormControl('', Validators.required),
			expirationYear: new FormControl('', Validators.required),
			firstAddress: new FormControl('', Validators.required),
			secondAddress: new FormControl(''),
			city: new FormControl('', Validators.required),
			zipCode: new FormControl('', Validators.required)
		});

		this.expirationDate = new FormControl('', [
			Validators.required,
			Validators.pattern('^(?=.*[0-9])[/0-9]+$'),
			Validators.minLength(6),
			Validators.maxLength(7)
		]);
	}

	public formatExpiryDate(value: any): void {
		value = this.expirationDate.value;
		if (!value.includes('/')) {
			value = value
				.replace(/\W/gi, '')
				.replace(/(.{2})/g, '$1/')
				.replace(/\/+$/, '');
			this.expirationDate.setValue(value);
		}

		const expires = value.split('/');
		this.cardDetails.controls['expirationMonth'].setValue(expires[0]);
		let yearValue = expires[1] || expires[0];
		if (expires[1] && expires[1].length == 3) {
			this.expirationDate.setErrors({ incorrect: true });
		}
		this.cardDetails.controls['expirationYear'].setValue(yearValue);
	}

	public creditCardNumberSpacing(value: any): void {
		value = this.cardDetails.get('cardNumber').value;
		value = value
			.replace(/\W/gi, '')
			.replace(/(.{4})/g, '$1 ')
			.replace(/\s+$/, '');
		this.cardDetails.get('cardNumber').setValue(value);
	}
	public onFormNameChange(): void {
		this.cardDetails.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {});
	}

	public getTarrifDetails(): void {
		this.submitting = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getUserTarrifPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				if (details) {
					details.amount = this.roundFigure(details.amount);
					var dates = details.nextInvoiceDate.split('T')[0].split('-');
					details.nextInvoiceDate = `${dates[2]}.${dates[1]}.${dates[0]}`;
				}
				this.planDetails = details;
			});
		this.submitting = false;
		this.sharedStore.dispatch(new HideGlobalSpinner());
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	public continueToAwesome(): void {
		this.router.navigate(['/authentication/awesome']);
	}
}
