import { FacebookAccountConnnection } from './../../_models/facebook-account-connection';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateFacebookAccountResponse } from 'src/app/_models/create-facebook-account-response';
import { BaseApiUrl } from '../base-api-urls';

@Injectable()
export class BusinessOwnerService {
	private readonly baseURL = BaseApiUrl.GoogleTuring;

	constructor(protected http: HttpClient) {}

	public deleteBusinessOwnerPermissions(permissions: string): Observable<any> {
		return this.http.delete(`${this.baseURL}business-owner/` + permissions);
	}

	public connectToFacebookAccount(request: FacebookAccountConnnection): Observable<HttpResponse<CreateFacebookAccountResponse>> {
		return this.http.post<CreateFacebookAccountResponse>(`${this.baseURL}business-owner`, request, { observe: 'response' });
	}
}
