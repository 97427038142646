import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { ConnectToGoogleRequest } from '../../authentication/_models/ConnectToGoogleRequest';
import { data } from 'jquery';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AdAccount } from 'src/app/accounts/models/ad-account.model';

@Injectable()
export class GoogleService {
	private readonly baseURL = BaseApiUrl.GoogleAccounts;
	public selectedGoogleAccount: BehaviorSubject<AdAccount> = new BehaviorSubject(null);
	public allGoogleAdAccounts: Map<SourceChannel, AdAccount[]> = new Map<SourceChannel, AdAccount[]>();

	public constructor(private http: HttpClient) {}

	public connectToGoogleAccount(request: ConnectToGoogleRequest): Observable<void> {
		return this.http.post<void>(`${this.baseURL}managed-customer/connect-to-google-account`, request);
	}
	public connectToGoogleUserAccount(redirectUrl: string): Observable<string> {
		return this.http.post<string>(`${BaseApiUrl.GoogleAccountsMain}user`, {
			redirectUrl: redirectUrl
		});
	}
	public checkGoogleStatus(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.GoogleAccountsMain}user`);
	}
	public checkGoogleStatusFromSignin(auth: string): Observable<{ status: boolean }> {
		const header = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${auth}`
		});
		return this.http.get<{ status: boolean }>(`${BaseApiUrl.GoogleAccountsMain}user/is-connected`, { headers: header });
	}
	public getListGoogleAds(): Observable<any> {
		return this.http.get(`${BaseApiUrl.GoogleAccountsMain}accounts?uncached=False`);
	}
	public listGoogleAds(): Observable<HttpResponse<any>> {
		return this.http.get(`${BaseApiUrl.GoogleAccountsMain}accounts?uncached=True`, { observe: 'response' });
	}
	public trackGoogleAds(data: any): Observable<HttpResponse<any>> {
		return this.http.post(`${BaseApiUrl.GoogleAccountsMain}accounts`, data, { observe: 'response' });
	}
}
