<section #mainSection *ngIf="!megaMenuOpen" [ngClass]="{ 'expanded-menu': expandMenu }" class="side-nav closed">
	<mat-sidenav #sidenav role="navigation">
		<div [ngClass]="{ 'no-menu-margin': !dropdownIsVisible }" class="menu-list" *ngIf="accountState !== userStateEnum.BillPendingRestrictedAccess">
			<mat-list>
				<mat-list-item
					#headerLogo
					(click)="toggleExpandNavMenu()"
					*appCheckPermissions="influencerPermission || creativeBuilderPermission"
					class="mat-list-top-item-content-expanded"
					[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
				>
					<img src="assets/icons/nav-menu/menu-stripe2.png" />
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item *appCheckPermissions="backOfficePermission" [routerLink]="'/back-office'" routerLinkActive="selectedItem">
					<mat-icon
						[ngClass]="{ 'nav-selected': currentUrl?.includes('back-office') && !currentUrl?.includes('creative-builder') }"
						class="nav-icons"
					>
						admin_panel_settings
					</mat-icon>
					<span
						[ngClass]="{ 'route-text-selected': currentUrl?.includes('back-office') && !currentUrl?.includes('creative-builder') }"
						class="route-text"
					>
						BACK OFFICE
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list>
				<mat-list-item *appCheckPermissions="backOfficePermission" [routerLink]="'/creative-builder-back-office'" routerLinkActive="selectedItem">
					<mat-icon [ngClass]="{ 'nav-selected': currentUrl?.includes('creative-builder') }" class="nav-icons">wallpaper</mat-icon>
					<span [ngClass]="{ 'route-text-selected': currentUrl?.includes('creative-builder') }" class="route-text">CB BACK OFFICE</span>
				</mat-list-item>
			</mat-list>
			<mat-list *ngFor="let mainItem of filteredMenuList; let i = index">
				<mat-list-item
					(click)="menuItemActive(i)"
					[ngStyle]="(mainItem.isSelected && !mainItem.childOpen) || (mainItem.childOpen && !expandMenu) ? menuItemSelectedEffect() : {}"
					[ngStyle]="mainItem.hoverOver && !mainItem.isSelected ? mouseEnterMenuEffect() : {}"
					(mouseleave)="mouseLeaveMenu(i)"
					(mouseenter)="mouseEnterMenu(i)"
					[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
					class="menu-item"
					*appCheckPermissions="influencerPermission || creativeBuilderPermission"
				>
					<img
						[ngClass]="{ 'filter-filed-purple': (mainItem.isSelected && !mainItem.childOpen) || (mainItem.childOpen && !expandMenu) }"
						class="filter-dark"
						[src]="mainItem.icon"
					/>
					<span
						[ngStyle]="(mainItem.isSelected && !mainItem.childOpen) || (mainItem.childOpen && !expandMenu) ? { color: '#5b5fc7' } : {}"
						class="route-text"
					>
						{{ mainItem.title }}
					</span>
					<img
						style="width: 10px; height: 6.25px; margin-right: 16px;"
						*ngIf="!mainItem.isSelected && mainItem.hasChildren && expandMenu"
						src="assets/icons/nav-menu/arrow-down.svg"
					/>
					<img
						style="width: 10px; height: 6.25px; margin-right: 16px;"
						*ngIf="mainItem.isSelected && mainItem.hasChildren && expandMenu"
						src="assets/icons/nav-menu/arrow-up.svg"
					/>
				</mat-list-item>
				<ng-container *ngIf="filteredSubMenuList && filteredSubMenuList.length > 0 && mainItem.hasChildren && mainItem.isSelected && expandMenu">
					<mat-list *ngFor="let item of filteredSubMenuList; let j = index">
						<mat-list-item
							(click)="menuItemSubActive(j)"
							[ngStyle]="item.isSelected ? menuItemSelectedEffect() : {}"
							[ngStyle]="item.hoverOver && !item.isSelected ? mouseEnterMenuEffect() : {}"
							(mouseleave)="mouseLeaveSubMenu(j)"
							(mouseenter)="mouseEnterSubMenu(j)"
							[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
							class="menu-item"
						>
							<div style="width: 16px; height: 16px;"></div>
							<span [ngStyle]="item.isSelected ? { color: '#5b5fc7' } : {}" class="route-text">
								{{ item.title }}
							</span>
						</mat-list-item>
					</mat-list>
				</ng-container>
			</mat-list>
		</div>
	</mat-sidenav>
</section>

<section #mainSection *ngIf="megaMenuOpen" class="side-nav closed mega-expanded-menu">
	<mat-sidenav #sidenav role="navigation">
		<div [ngClass]="{ 'no-menu-margin': !dropdownIsVisible }" class="menu-list" *ngIf="accountState !== userStateEnum.BillPendingRestrictedAccess">
			<mat-list *ngFor="let item of filteredMegaMenuList; let i = index">
				<mat-list-item
					(click)="megaMenuItemActive(i)"
					(mouseleave)="mouseLeaveMegaMenu(i)"
					(mouseenter)="mouseEnterMegaMenu(i)"
					[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
					[ngStyle]="item.isSelected ? menuItemSelectedEffect() : {}"
					[ngStyle]="item.hoverOver && !item.isSelected ? mouseEnterMenuEffect() : {}"
					class="menu-item"
				>
					<img class="filter-dark" style="width: 20px; height: 20px;" [src]="item.icon" />
					<span class="route-text">
						{{ item.title }}
					</span>
				</mat-list-item>
			</mat-list>
		</div>
	</mat-sidenav>
</section>

<section [ngClass]="{ 'side-container-wide': expandMenu }" class="side-container closed">
	<div [ngClass]="{ 'header-wide': expandMenu }" class="header closed">
		<mat-toolbar class="header_toolbar">
			<div
				*ngIf="!this.isAdmin"
				style="z-index: 3;"
				(click)="toggleExpandMegaMenu()"
				[ngClass]="{ 'expanded-header-logo': expandMenu, 'expanded-mega-logo': megaMenuOpen, 'mega-top-logo-img': megaMenuOpen }"
				class="header-logo"
				routerLink="/accounts"
			>
				<img *ngIf="!megaMenuOpen" src="assets/icons/nav-menu/dots-menu-2.png" />
				<img *ngIf="megaMenuOpen" src="assets/icons/nav-menu/dots-menu-black.png" />
				<img *ngIf="megaMenuOpen" src="assets/icons/nav-menu/lolly-black.svg" />
			</div>

			<div *ngIf="!megaMenuOpen" class="top-logo-img">
				<img src="../../../assets/logo/lolly-L-logo-white.svg" />
			</div>

			<!--New Dropdown Menu-->
			<!-- <app-multi-channel-account-selection *ngIf="dropdownIsVisible && !hasSMI"></app-multi-channel-account-selection> -->

			<span fxFlex></span>

			<div *ngIf="isImpersonated" class="impersonation-exit">
				<div class="impersonation-content">
					<p class="impersonation-text">You are in Impersonation Mode. Any changes you make will impact your client.</p>
					<btn07 (onClick)="exitImpersonation()">EXIT</btn07>
				</div>
			</div>

			<div class="header_icons">
				<div class="unpaid-invoice-btn" *ngIf="this.unpaidInvoice">
					<a [routerLink]="['/user-management/billing-and-payment']">Unpaid Invoice</a>
					<div class="unpaid-description">There is an issue with your card. We were unable to bill you. Please add a new payment.</div>
				</div>
				<div (click)="openKnowledgeBase()" class="profile-icon q-icon">
					{{ '?' }}
				</div>
				<div #settingsIcon (click)="toggleProfileAction()" class="profile-icon">
					{{ userFName?.charAt(0) + userLName?.charAt(0) }}
				</div>
			</div>
		</mat-toolbar>
	</div>

	<div class="main-container closed">
		<mat-sidenav-container>
			<mat-sidenav-content (scroll)="onScroll($event)">
				<router-outlet></router-outlet>
				<lolly-spinning-loader [rotating]="true" class="loading-indicator" *ngIf="showSpinner"></lolly-spinning-loader>
			</mat-sidenav-content>
		</mat-sidenav-container>
	</div>
</section>

<div #settingsPopup class="profile-card" *ngIf="profileOpen">
	<div class="avatar-personal">
		<div class="avatar">
			{{ userFName?.charAt(0) + userLName?.charAt(0) }}
		</div>
		<div class="personal">
			<div class="top">{{ userFName + ' ' + userLName }}</div>
			<div class="bottom">{{ userEmail }}</div>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div (click)="goToBilling()" class="action">
		<img src="assets/icons/nav-menu/settings.svg" />
		<div class="text">{{ 'Settings' }}</div>
	</div>
	<mat-divider></mat-divider>
	<div (click)="goToUserPage()" class="action">
		<img src="assets/icons/nav-menu/accounts.svg" />
		<div class="text">{{ 'Manage Accounts' }}</div>
	</div>
	<mat-divider></mat-divider>
	<div (click)="logout()" class="action">
		<img src="assets/icons/nav-menu/signout.svg" />
		<div class="text">{{ 'Sign out' }}</div>
	</div>
</div>

<app-toast-notification class="toast-position"></app-toast-notification>
<ntf01></ntf01>
<ntf02></ntf02>

<!-- temporary -->
<ng-template #firstTimeLogin>
	<h2 mat-dialog-title>Thanks for using Lolly !</h2>
	<mat-dialog-content class="mat-typography restrict-users-dialog">
		<h3>
			The platform is undergoing development for our upcoming release and therefore new users don’t have access just yet. Please rest assured Dexter is
			still working in the background to analyse your campaign(s). Please get in touch with your Client Success Manager regarding any questions.
		</h3>
		<app-button (buttonClicked)="logout()" [buttonClass]="buttonClassEnum.Primary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="Logout"></app-button>
	</mat-dialog-content>
</ng-template>
