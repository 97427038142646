import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { DexterWelcomeComponent } from './new-signup/dexter-welcome/dexter-welcome.component';
import { EmailRegisterComponent } from './new-signup/email-register/email-register.component';
import { EmailVerificationComponent } from './new-signup/email-verification/email-verification.component';
import { LandingPageComponent } from './new-signup/landing-page/landing-page.component';
import { LunchComponents } from './new-signup/lunch/lunch.component';
import { PasswordComponent } from './new-signup/password/password.component';
import { PaymentConfirmation } from './new-signup/payment-confirmation/payment-confirmation.component';
import { PaymentPageComponent } from './new-signup/payment-page/payment-page.component';
import { PersonalDetailComponent } from './new-signup/personal-detail/personal-detail.component';
import { PersonalWelcomeComponent } from './new-signup/personal-welcome/personal-welcome.component';
import { AuthenticationRouteGuard } from '../shared/guards/authentication-route.guard';
import { ResetPasswordWithEmailComponent } from './reset-password-with-email/reset-password-with-email.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { AwesomeComponent } from './new-signup/awesome/awesome.component';
import { MobileLunchComponent } from './new-signup/mobile-lunch/mobile-lunch.component';
import { RetryPaymentComponent } from './new-signup/retry-payment/retry-payment.component';
import { RetryPaymentConfirmationComponent } from './new-signup/retry-payment-confirmation/retry-payment-confirmation.component';

export const routes: Routes = [
	{ path: '', component: SigninComponent },
	{ path: 'reset-password-with-email', component: ResetPasswordWithEmailComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'forgot-password', component: SetPasswordComponent },
	{ path: 'sign-up', component: LandingPageComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'register-email', component: EmailRegisterComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'personal-detail', component: PersonalDetailComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'verify-email', component: EmailVerificationComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'dexter-welcome', component: DexterWelcomeComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'register-password', component: PasswordComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'set-first-password', component: PasswordComponent },
	{ path: 'personal-welcome', component: PersonalWelcomeComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'payment', component: PaymentPageComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'payment-confirmation', component: PaymentConfirmation, canActivate: [AuthenticationRouteGuard] },
	{ path: 'retryPayment', component: RetryPaymentComponent },
	{ path: 'retry-payment-confirmation', component: RetryPaymentConfirmationComponent },
	{ path: 'launch', component: LunchComponents, canActivate: [AuthenticationRouteGuard] },
	{ path: 'awesome', component: AwesomeComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'mobile-welcome', component: MobileLunchComponent, canActivate: [AuthenticationRouteGuard] }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
