import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (!this.authService.isLoggedIn()) {
			this.router.navigate(['/authentication']);
			return of(false);
		}

		const hasBusinessOwner$ = this.authService.hasBusinessOwner();
		const hasCreditCard$ = this.authService.hasCreditCard();
		const isAdmin$ = this.authService.isAdmin();
		const isFreemiumExpired$ = this.authService.isFreemiumExpired();
		const isFreeTrialExpired$ = this.authService.isFreeTrialExpired();

		return forkJoin([hasCreditCard$, isAdmin$, hasBusinessOwner$, isFreemiumExpired$, isFreeTrialExpired$]).pipe(
			switchMap(([hasCreditCard, isAdmin, hasBusinessOwner, isFreemiumExpired, isFreeTrialExpired]) => {
				if (!hasBusinessOwner && !isAdmin) {
					this.router.navigate(['/auth-landing']);
					return of(false);
				} else if (!hasCreditCard && !isAdmin) {
					this.router.navigate(['/authentication/payment']);
					return of(false);
				} else if (isFreemiumExpired || isFreeTrialExpired) {
					this.router.navigate(['/authentication/trial-end']);
					return of(false);
				}

				return of(true);
			})
		);
	}
}
