import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EChartOption, ECharts } from 'echarts';
import {
	getGlobalDate,
	getSelectedAdAccount,
	getSelectedElementsMetrics,
	getToggleElementsGraph,
	getToggleElementsRecommendations,
	getCurrentAdsManagerTab,
	SharedState
} from '../../state/shared.reducer';
import { SetToggleElementsGraph, SetToggleElementsRecommendations } from '../../state/shared.actions';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ElementsService } from '../../../_services/elements.service';
import { ChartDataInterface } from '../../../_models/elements-models/chart-data.interface';
import moment from 'moment';
import { ToastNotificationService } from '../../toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataResult } from '../../../_models/elements-models/chart-data-result.interface';
import { XAxisData } from '../../../_models/elements-models/chart-xaxis.interface';
import { ChartSeries } from '../../../_models/elements-models/chart-series.interface';
import { ChartResultEnum } from '../../../_models/elements-models/chart-result.enum';
import { UserPreferencesDetails } from '../../../_models/elements-models/user-preferences-details.interface';
import { CampaignInterface } from '../../../ads-manager/ads-manager-insights/models/campaign.interface';
import { AdsManagerState, getSelectedCampaigns, getSelectedAdSets, getSelectedAds } from '../../../ads-manager/state/ads-manager.reducer';
import { InsightsCategoryTypeEnum } from '../../campaign-insights/models/insights-category-type.enum';
import { AdSetsInterface } from '../../../ads-manager/ads-manager-insights/models/ad-sets.interface';
import { AdsInterface } from '../../../ads-manager/ads-manager-insights/models/ads.interface';
import { ChartOperatorsEnum } from '../../../_models/elements-models/chart-operators.enum';
import { ChartSteps } from '../../../_models/elements-models/chart-steps.enum';
import { ChartStepsNumbers } from '../../../_models/elements-models/chart-steps-numbers.enum';

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
	@Input() public accounts: boolean;
	public overviewTextSize: number = 14;

	public chartOption: EChartOption = {};
	public chartType = 'line';
	public showChart: boolean;
	public chartElement: HTMLDivElement;
	public myChart: ECharts;
	public showRecommendations: boolean;
	public chartData: ChartDataInterface;
	public dateStart: string;
	public dateEnd: string;
	public facebookAccountId: string;
	public resultChart: ChartDataResult[];
	public xAxis: XAxisData[];
	public yAxis: ChartSeries[];
	public graphLegend: string[];
	public isArea: boolean;
	public userPreferencesJson: UserPreferencesDetails;
	public loading: boolean;
	public selectedCampaigns: string[];
	public selectedAdSets: string[];
	public selectedAds: string[];
	public activeCampaignFilter: boolean;
	public activeAdSetsFilter: boolean;
	public activeAdsFilter: boolean;
	public adsTabChanges: boolean;
	public currentAdsTab: string;
	public yAxisDefinition: object[];

	private unsubscriber$ = new Subject<void>();

	constructor(
		private sharedStore: Store<SharedState>,
		private elementsService: ElementsService,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService,
		private adsManagerStore: Store<AdsManagerState>
	) {}

	public ngOnInit(): void {
		this.changeTextSize();
		this.checkDataSaved();
		this.getGraphDisplay();
		this.getRecommendationsDisplay();
		this.getSelectedTime();
		this.getSelectedCampaigns();
		this.getCurrentAdsManagerTab();
	}

	public changeTextSize(): void {
		let screenSize: number = screen.width;
		if (screenSize < 1537) {
			this.overviewTextSize = 13;
		}

		this.chartOption = {
			title: {
				text: 'Overview',
				textStyle: {
					fontSize: this.overviewTextSize,
					color: '#0B0B0B'
				}
			},
			tooltip: {
				trigger: 'axis',
				backgroundColor: '#fff',
				extraCssText: 'box-shadow: 0px 3px 6px #00000029;',
				textStyle: {
					fontSize: 12,
					color: '#0B0B0B',
					lineHeight: 25
				},
				padding: [10, 15]
			},
			legend: {
				right: 50,
				icon: 'circle',
				data: [],
				itemGap: 45,
				textStyle: {
					color: '#0B0B0B'
				}
			},
			grid: {
				left: '7px',
				right: '7px',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				axisLine: { lineStyle: { color: '#d9d9d9' } },
				axisTick: { show: false },
				axisLabel: { color: '#484848', margin: 15 },
				data: [],
				splitLine: { show: false }
			},
			yAxis: [
				{
					type: 'value',
					axisLabel: { show: false },
					axisLine: { show: false },
					axisTick: { show: false },
					splitLine: { lineStyle: { type: 'dashed' } },
					splitNumber: 3,
					gridIndex: 0
				}
			],
			series: []
		};
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public checkDataSaved(): void {
		this.userPreferencesJson = {};
		// this.elementsService
		// 	.getUserPreferences()
		// 	.pipe(takeUntil(this.unsubscriber$))
		// 	.subscribe(result => {
		// 		this.userPreferencesJson = result.details;

		// 		if (this.accounts) {
		// 			if (this.userPreferencesJson.accountsElements && this.userPreferencesJson.accountsElements.showChart !== undefined) {
		// 				this.sharedStore.dispatch(new SetToggleElementsGraph(this.userPreferencesJson.accountsElements.showChart));
		// 			} else {
		// 				this.sharedStore.dispatch(new SetToggleElementsGraph(true));
		// 			}

		// 			if (this.userPreferencesJson.accountsElements && this.userPreferencesJson.accountsElements.showRecommendations !== undefined) {
		// 				this.sharedStore.dispatch(new SetToggleElementsRecommendations(this.userPreferencesJson.accountsElements.showRecommendations));
		// 			} else {
		// 				this.sharedStore.dispatch(new SetToggleElementsRecommendations(true));
		// 			}
		// 		} else {
		// 			if (this.userPreferencesJson.adsManagerElements && this.userPreferencesJson.adsManagerElements.showChart !== undefined) {
		// 				this.sharedStore.dispatch(new SetToggleElementsGraph(this.userPreferencesJson.adsManagerElements.showChart));
		// 			} else {
		// 				this.sharedStore.dispatch(new SetToggleElementsGraph(false));
		// 			}

		// 			if (this.userPreferencesJson.adsManagerElements && this.userPreferencesJson.adsManagerElements.showRecommendations !== undefined) {
		// 				this.sharedStore.dispatch(new SetToggleElementsRecommendations(this.userPreferencesJson.adsManagerElements.showRecommendations));
		// 			} else {
		// 				this.sharedStore.dispatch(new SetToggleElementsRecommendations(true));
		// 			}
		// 		}
		// 	});
	}

	public updateUserPreferences(data: UserPreferencesDetails): void {
		// this.elementsService
		// 	.updateUserPreferences(JSON.stringify(data))
		// 	.pipe(takeUntil(this.unsubscriber$))
		// 	.subscribe(
		// 		() => {
		// 			this.checkDataSaved();
		// 		},
		// 		() => {
		// 			this.toastNotificationService.sendErrorToast('We could not save your preferences, please try again');
		// 		}
		// 	);
	}

	public initChart(instance: ECharts): void {
		if (this.showChart) {
			this.checkDataSaved();
			this.myChart = instance;
			this.myChart.clear();
			this.myChart.setOption(this.chartOption);
			this.getGraphData();
			this.myChart.setOption({ xAxis: { data: this.xAxis } });
			this.myChart.setOption({ yAxis: this.yAxisDefinition });
			this.myChart.setOption({ series: this.yAxis });

			// KEEP LEGENDS DOCKED TO RIGHT
			// if (!this.showRecommendations) {
			// 	this.myChart.setOption({ legend: { right: 260 } });
			// } else {
			// 	this.myChart.setOption({ legend: { right: 50 } });
			// }
		}
	}

	public getRecommendationsDisplay(): void {
		this.sharedStore.pipe(select(getToggleElementsRecommendations), takeUntil(this.unsubscriber$)).subscribe(recommendations => {
			this.showRecommendations = recommendations;

			if (this.myChart) {
				if (!this.showRecommendations) {
					this.myChart.setOption({ legend: { right: 260 } });
				} else {
					this.myChart.setOption({ legend: { right: 50 } });
				}
			}
		});
	}

	public changeRecommendationsToggle(): void {
		this.sharedStore.dispatch(new SetToggleElementsRecommendations(!this.showRecommendations));

		if (this.accounts) {
			this.userPreferencesJson.accountsElements = this.userPreferencesJson.accountsElements || {};
			this.userPreferencesJson.accountsElements.showRecommendations = this.showRecommendations;
		} else {
			this.userPreferencesJson.adsManagerElements = this.userPreferencesJson.adsManagerElements || {};
			this.userPreferencesJson.adsManagerElements.showRecommendations = this.showRecommendations;
		}
		this.updateUserPreferences(this.userPreferencesJson);
	}

	public getGraphDisplay(): void {
		this.sharedStore.pipe(select(getToggleElementsGraph), takeUntil(this.unsubscriber$)).subscribe(graph => {
			this.showChart = graph;
		});
	}

	public getGraphData(): void {
		this.sharedStore.pipe(select(getSelectedElementsMetrics), takeUntil(this.unsubscriber$)).subscribe(metrics => {
			delete this.facebookAccountId;
			this.getSelectedAdAccount();
			if (metrics.Columns?.length > 0 && this.dateStart && this.dateEnd && this.facebookAccountId && this.myChart) {
				this.loading = true;
				this.myChart.clear();
				this.myChart.setOption(this.chartOption);

				this.chartData = {
					TableName: 'vCampaignInsights',
					Columns: [],
					Dimensions: [{ GroupColumnName: 'date_start' }, { GroupColumnName: 'day' }],
					Where: {
						LogicalOperator: 1,
						Conditions: null,
						ChildConditions: [
							{
								LogicalOperator: 0,
								Conditions: [
									{
										ColumnName: 'date_start',
										Operator: ChartOperatorsEnum.greaterThanOrEqual,
										Value: this.dateStart
									},
									{
										ColumnName: 'date_stop',
										Operator: ChartOperatorsEnum.lessThanOrEqual,
										Value: this.dateEnd
									},
									{
										ColumnName: 'account_id',
										Operator: ChartOperatorsEnum.equal,
										Value: this.facebookAccountId
									},
									{
										ColumnName: 'time_increment',
										Operator: ChartOperatorsEnum.in,
										Value: 1
									}
								]
							}
						]
					}
				};

				if (this.selectedCampaigns && this.selectedCampaigns.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.Campaign) {
					this.chartData.TableName = 'vCampaignInsights';
					this.chartData.Where.ChildConditions[0].Conditions.push({
						ColumnName: 'campaign.id',
						Operator: ChartOperatorsEnum.in,
						Value: this.selectedCampaigns
					});

					this.activeCampaignFilter = true;
				} else if (this.selectedAdSets && this.selectedAdSets.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.AdSet) {
					this.chartData.TableName = 'vAdSetInsights';
					this.chartData.Where.ChildConditions[0].Conditions.push({
						ColumnName: 'adset.id',
						Operator: ChartOperatorsEnum.in,
						Value: this.selectedAdSets
					});

					this.activeAdSetsFilter = true;
				} else if (this.selectedAds && this.selectedAds.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.Ad) {
					this.chartData.TableName = 'vAdInsights';
					this.chartData.Where.ChildConditions[0].Conditions.push({
						ColumnName: 'ad.id',
						Operator: ChartOperatorsEnum.in,
						Value: this.selectedAds
					});

					this.activeAdsFilter = true;
				}

				this.yAxis = [];
				this.graphLegend = [];

				this.yAxisDefinition = [
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: true, lineStyle: { type: 'dashed' } },
						splitNumber: 3,
						position: 'left'
					},
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: false },
						splitNumber: 3,
						max: ChartSteps.step1,
						position: 'left'
					},
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: false },
						splitNumber: 3,
						max: ChartSteps.step2,
						position: 'left',
						interval: ChartSteps.step2 / 3
					},
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: false },
						splitNumber: 3,
						max: ChartSteps.step3,
						position: 'left',
						interval: ChartSteps.step3 / 3
					},
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: false },
						splitNumber: 3,
						max: ChartSteps.step4,
						position: 'left',
						interval: ChartSteps.step4 / 3
					},
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: false },
						splitNumber: 3,
						max: ChartSteps.step5,
						position: 'left',
						interval: ChartSteps.step5 / 3
					},
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: false },
						splitNumber: 3,
						max: ChartSteps.step6,
						position: 'left',
						interval: ChartSteps.step6 / 3
					},
					{
						type: 'value',
						axisLabel: { show: false },
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: { show: false },
						splitNumber: 3,
						max: ChartSteps.step7,
						position: 'left',
						interval: ChartSteps.step7 / 3
					}
				];

				const colorsArr: string[] = [];

				metrics.Columns.forEach((item, index) => {
					if (!item.disabled) {
						this.chartData.Columns.push({ Name: item.field });
						this.yAxis.push({
							name: item.headerName,
							id: item.field,
							type: this.chartType,
							smooth: true,
							data: [],
							lineStyle: { color: item.color },
							areaStyle: { opacity: this.isArea ? 0.21 : 0 }
						});
						this.graphLegend.push(item.headerName);
						colorsArr.push(item.color);
					}
				});

				this.elementsService
					.getChartData(this.chartData)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(
						result => {
							this.resultChart = result;
							this.xAxis = [];
							this.resultChart.forEach((item: any, index) => {
								const date_start = moment(item.date_start);

								if (index === 0) {
									this.xAxis.push({ value: date_start.format('DD MMM YYYY'), textStyle: { align: 'left' } });
								} else if (index + 1 === this.resultChart.length) {
									this.xAxis.push({ value: date_start.format('DD MMM YYYY'), textStyle: { align: 'right' } });
								} else {
									this.xAxis.push({ value: date_start.format('DD MMM YYYY') });
								}

								const itemArray2: string[] = [];

								Object.keys(item).map((key: any) => (itemArray2[key] = item[key]));

								Object.keys(itemArray2).forEach(key => {
									if (key !== ChartResultEnum.day && key !== ChartResultEnum.date_start) {
										const indexFound = this.yAxis.findIndex(obj => obj.id === key);
										if (indexFound !== -1) {
											this.yAxis[indexFound].data.push((itemArray2 as any)[key]?.toFixed(2));
										}
									}
								});
							});
							this.myChart.setOption({ color: colorsArr });
							this.myChart.setOption({ xAxis: { data: this.xAxis } });
							this.yAxis.forEach((item, index) => {
								const max = Math.max(...item.data);
								if (max < ChartSteps.step1) {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step1;
								} else if (max > ChartSteps.step1 && max < ChartSteps.step2) {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step2;
								} else if (max > ChartSteps.step2 && max < ChartSteps.step3) {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step3;
								} else if (max > ChartSteps.step3 && max < ChartSteps.step4) {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step4;
								} else if (max > ChartSteps.step4 && max < ChartSteps.step5) {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step5;
								} else if (max > ChartSteps.step5 && max < ChartSteps.step6) {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step6;
								} else if (max > ChartSteps.step6 && max < ChartSteps.step7) {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step7;
								} else {
									this.yAxis[index].yAxisIndex = ChartStepsNumbers.step0;
								}
							});
							this.myChart.setOption({ yAxis: this.yAxisDefinition });
							this.myChart.setOption({ series: this.yAxis });
							this.myChart.setOption({ legend: { data: this.graphLegend } });
							if (!this.showRecommendations) {
								this.myChart.setOption({ legend: { right: 260 } });
							} else {
								this.myChart.setOption({ legend: { right: 50 } });
							}
							this.loading = false;
						},
						error => {
							this.toastNotificationService.sendErrorToast(
								this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT')
							);
							this.myChart.setOption({ series: [] });
							this.myChart.setOption({ legend: { data: this.graphLegend } });
							if (!this.showRecommendations) {
								this.myChart.setOption({ legend: { right: 260 } });
							} else {
								this.myChart.setOption({ legend: { right: 50 } });
							}
							this.loading = false;
						}
					);
			}
		});
	}

	public getSelectedTime(): void {
		this.sharedStore.pipe(select(getGlobalDate), takeUntil(this.unsubscriber$)).subscribe(date => {
			this.dateStart = date.date.startDate.format('YYYY-MM-DD');
			this.dateEnd = date.date.endDate.format('YYYY-MM-DD');
		});
	}

	public getSelectedAdAccount(): void {
		this.sharedStore.pipe(select(getSelectedAdAccount), take(1)).subscribe(data => {
			this.facebookAccountId = data.adAccount.id;
		});
	}

	public changeChartType(type: string, area: boolean): void {
		this.chartType = type;
		this.isArea = area;
		this.yAxis.forEach((item, index) => {
			this.yAxis[index].type = this.chartType;
			this.yAxis[index].areaStyle = { opacity: this.isArea ? 0.21 : 0 };
		});

		this.myChart.setOption({ series: this.yAxis });

		if (this.accounts) {
			this.userPreferencesJson.accountsElements = this.userPreferencesJson.accountsElements || {};
			this.userPreferencesJson.accountsElements.chartType = this.chartType;
			this.userPreferencesJson.accountsElements.showRecommendations = this.showRecommendations;
		} else {
			this.userPreferencesJson.adsManagerElements = this.userPreferencesJson.adsManagerElements || {};
			this.userPreferencesJson.adsManagerElements.chartType = this.chartType;
			this.userPreferencesJson.adsManagerElements.showRecommendations = this.showRecommendations;
		}
		this.updateUserPreferences(this.userPreferencesJson);
	}

	public changeGraphDisplay(): void {
		this.showChart ? (this.showChart = false) : (this.showChart = true);
		this.sharedStore.dispatch(new SetToggleElementsGraph(this.showChart));
		if (this.accounts) {
			this.userPreferencesJson.accountsElements = this.userPreferencesJson.accountsElements || {};
			this.userPreferencesJson.accountsElements.showChart = this.showChart;
		} else {
			this.userPreferencesJson.adsManagerElements = this.userPreferencesJson.accountsElements || {};
			this.userPreferencesJson.adsManagerElements.showChart = this.showChart;
		}
		this.updateUserPreferences(this.userPreferencesJson);
	}

	public getSelectedCampaigns(): void {
		if (!this.accounts) {
			this.adsManagerStore
				.pipe(select(getSelectedCampaigns), debounceTime(1000), takeUntil(this.unsubscriber$))
				.subscribe((selectedCampaigns: CampaignInterface[]) => {
					this.selectedCampaigns = [];

					if (selectedCampaigns && selectedCampaigns.length > 0) {
						selectedCampaigns?.forEach(item => {
							this.selectedCampaigns.push(item.campaign_id);
						});

						this.getGraphData();
					}

					if ((this.activeCampaignFilter && !selectedCampaigns) || (this.activeCampaignFilter && selectedCampaigns.length === 0)) {
						this.getGraphData();
					}
				});
		}
	}

	public getSelectedAdSets(): void {
		if (!this.accounts) {
			this.adsManagerStore
				.pipe(select(getSelectedAdSets), debounceTime(1000), takeUntil(this.unsubscriber$))
				.subscribe((selectedAdSets: AdSetsInterface[]) => {
					this.selectedAdSets = [];

					if (selectedAdSets && selectedAdSets.length > 0) {
						selectedAdSets?.forEach(item => {
							this.selectedAdSets.push(item.adset_id);
						});

						this.getGraphData();
					}

					if ((this.activeAdSetsFilter && !selectedAdSets) || (this.activeAdSetsFilter && selectedAdSets.length === 0)) {
						this.getGraphData();
					}
				});
		}
	}

	public getSelectedAds(): void {
		if (!this.accounts) {
			this.adsManagerStore.pipe(select(getSelectedAds), debounceTime(1000), takeUntil(this.unsubscriber$)).subscribe((selectedAds: AdsInterface[]) => {
				this.selectedAds = [];

				if (selectedAds && selectedAds.length > 0) {
					selectedAds?.forEach(item => {
						this.selectedAds.push(item.ad_id);
					});

					this.getGraphData();
				}

				if ((this.activeAdsFilter && !selectedAds) || (this.activeAdsFilter && selectedAds.length === 0)) {
					this.getGraphData();
				}
			});
		}
	}

	public getCurrentAdsManagerTab(): void {
		if (!this.accounts) {
			this.sharedStore.pipe(select(getCurrentAdsManagerTab), takeUntil(this.unsubscriber$)).subscribe(currentTab => {
				this.currentAdsTab = currentTab;
				if (this.adsTabChanges && this.currentAdsTab === InsightsCategoryTypeEnum.Campaign) {
					this.getSelectedCampaigns();
				} else if (this.currentAdsTab === InsightsCategoryTypeEnum.AdSet) {
					this.getSelectedAdSets();
				} else if (this.currentAdsTab === InsightsCategoryTypeEnum.Ad) {
					this.getSelectedAds();
				}
				this.adsTabChanges = true;
			});
		}
	}
}
