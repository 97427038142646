import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { Router } from '@angular/router';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { RegisterUserEmail } from '../../state/authentication.action';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';

@Component({
	selector: 'app-email-register',
	templateUrl: './email-register.component.html',
	styleUrls: ['./email-register.component.scss']
})
export class EmailRegisterComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public emailControl: FormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;

	constructor(
		private router: Router,
		private store: Store<AuthenticationState>,
		private user: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		private sharedStore: Store<SharedState>
	) {}

	public ngOnInit(): void {
		const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.emailControl = new FormControl(null, [Validators.required, Validators.maxLength(70), Validators.pattern(emailValidator)]);
		this.formChanges();
		this.getRegistrationDetails();
	}

	public ngOnDestroy(): void {}

	public formChanges(): void {
		this.emailControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
	}

	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.emailControl.setValue(detail.email);
			}
		});
	}

	public checkForInvalid(): void {
		const email = this.emailControl.value;
		if (!this.emailControl) {
			this.emailControl.setErrors({ dialCode: true });
		}
	}

	public continueSignup(): void {
		this.submitting = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.user
			.confirmUserEmail(this.emailControl.value)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				status => {
					if (status) {
						this.store.dispatch(new RegisterUserEmail(this.emailControl.value));
						this.submitting = true;
						this.sleep(3000);
						this.router.navigate(['/authentication/personal-detail']);
					} else {
						this.toastNotificationService.sendErrorToast('This email is already associated with an account.');
					}
				},
				() => {},
				() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public sleep(ms: number): Promise<any> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}
