import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdAccountApiService } from '../../_services/facebook-accounts/ad-account-api.service';
import { AuthenticationState, getOtpDialogState, getRedirectUrl } from '../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { LogIn, ShowOtpInput } from '../state/authentication.action';
import { IBigCommerceAuth, LogInModel } from '../_models/login.model';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { TemporaryCredentials } from '../../_models/user-models/user';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { FieldValidators } from '../../user-management/models/validators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AppState, AppStateSlices } from 'src/app/state/app.state';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { EMPTY, Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { isDevMode } from '@angular/core';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BillingAndSubscriptionService } from 'src/app/shared/services/billing-and-subscriptions.service';
import { VerifyAddUserComponent } from 'src/app/user-management/new-sprint/components/modals/verify-add-user/verify-add-user.component';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
	selector: 'app-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {
	public signInForm: FormGroup;
	public emailFormControl: FormControl;
	public passwordFormControl: FormControl;
	public isAccountSignedIn = false;
	public loading = false;
	public hasRedirectParam: boolean;
	public validationMessages: ValidatorMessages[];
	public isFromBigCommerce = false;
	private bigCommerceObj: IBigCommerceAuth;
	private unsubscriber$ = new Subject<void>();
	public subscribeToBillingId: any;

	constructor(
		private router: Router,
		private adAccountService: AdAccountApiService,
		private activatedRoute: ActivatedRoute,
		private authStore: Store<AuthenticationState>,
		private store: Store<AppState>,
		private authenticationService: AuthenticationService,
		private userService: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		private bilingService: BillingAndSubscriptionService,
		public dialog: MatDialog,
		private _location: Location
	) {
		this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
			this.activatedRoute.queryParams.subscribe(params => {
				this.hasRedirectParam = params[redirectUrl] === 'true';

				// controls for when route is hit from bigcommerce
				this.isFromBigCommerce = params.platform && params.platform === 'bigcommerce';
				if (this.isFromBigCommerce) {
					this.bigCommerceObj = {
						platform: params.platform,
						storeEmail: params.store_email,
						idInPlatform: params.id_in_platform,
						scope: params.scope,
						storeHash: params.store_hash,
						accessToken: params.access_token,
						storeFrontToken: params.storefront_token
					};
				}
			});
		});
	}

	public ngOnInit(): void {
		const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		this.emailFormControl = new FormControl('', [Validators.required, Validators.pattern(emailValidator), Validators.maxLength(70)]);
		this.passwordFormControl = new FormControl('', [Validators.required]);

		this.signInForm = new FormGroup({
			email: this.emailFormControl,
			password: this.passwordFormControl
		});

		if (this.hasRedirectParam && !this.isFromBigCommerce) {
			this.authStore.dispatch(new LogIn());
		}

		this.toggleLoader();
		this.listenToOTPRequest();

		this.bilingService
			.getSubcribeToId()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.subscribeToBillingId = isDevMode() ? '1' : '35';
					return EMPTY;
				})
			)
			.subscribe(value => {
				if (value) {
					this.subscribeToBillingId = value;
				} else {
					this.subscribeToBillingId = isDevMode() ? '1' : '35';
				}
			});
	}

	public listenToOTPRequest(): void {
		this.authStore.pipe(select(getOtpDialogState), takeUntil(this.unsubscriber$)).subscribe((status: boolean) => {
			if (status) {
				const dialogRef = this.dialog.open(VerifyAddUserComponent, {
					width: '520px',
					height: '480px',
					disableClose: true
				});
				dialogRef.componentInstance.modelDetail = {
					title: 'Two-Factor Authentication!',
					text: 'A message has been sent to the provided phone number. Please enter 6 digit code to authorize this signin.',
					closeBtn: true,
					actionBtn: true,
					actionBtnText: 'CONFIRM',
					useOtp: true,
					otp: {
						secret: false,
						count: 6
					}
				};
				dialogRef.afterClosed().subscribe(details => {
					if (details && details?.status) {
						const temporaryLoginEmail = this.emailFormControl.value.trim();
						const temporaryLoginPassword = this.passwordFormControl.value.trim();
						const loginPayload: LogInModel = {
							email: temporaryLoginEmail,
							password: temporaryLoginPassword,
							code: details.code
						};
						this.authStore.dispatch(new LogIn(loginPayload));
						this.loading = true;
					} else {
						this.authenticationService.logout();
					}
				});
			}
		});
	}

	public onForgotPasswordClick(): void {
		this.router.navigate(['/authentication/reset-password-with-email']);
	}

	public onSubmit(): void {
		if (this.signInForm.invalid) {
			return;
		}
		this.loading = true;

		const temporaryLoginEmail = this.emailFormControl.value.trim();
		const temporaryLoginPassword = this.passwordFormControl.value.trim();

		if (!this.hasRedirectParam) {
			const temporaryCredentials = {
				emailAddress: this.emailFormControl.value.trim(),
				password: this.passwordFormControl.value.trim()
			};
		}

		const loginPayload: LogInModel = {
			email: temporaryLoginEmail,
			password: temporaryLoginPassword,
			code: ''
		};

		if (!this.isFromBigCommerce) {
			this.authStore.dispatch(new LogIn(loginPayload));
			this.toggleLoader();
		} else if (this.isFromBigCommerce) {
			this.bigCommerceObj.emailAddress = temporaryLoginEmail;
			this.bigCommerceObj.password = temporaryLoginPassword;
			this.authBigCommerceUser(this.bigCommerceObj);
		}
	}

	private toggleLoader(): void {
		this.store.select('authentication').subscribe(res => {
			if (!res.isAuthenticated) {
				this.loading = false;
			}
		});
	}

	public onSubscribeToAnOffer(): void {
		this.router.navigateByUrl(`/authentication/sign-up`);
	}

	private authBigCommerceUser(payload: IBigCommerceAuth) {
		this.userService
			.bigCommerceAuth(payload)
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('There was an error authenticating user for big commerce ' + error.toString());
					return EMPTY;
				})
			)
			.subscribe(value => {
				if (value.status === 200) {
					const loginPayload: LogInModel = {
						email: payload.emailAddress,
						password: payload.password,
						code: ''
					};
					this.authenticationService.bigCommerceAction$.next(true);
					this.authStore.dispatch(new LogIn(loginPayload));
					this.toggleLoader();
				} else {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('There was an error authenticating user for big commerce ');
				}
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
