import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { DatatableEnum } from 'src/app/user-management/models/DatatableEnum';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import moment from 'moment';
import { PopupDialogService } from 'src/app/_services/popup-dialog.service';
import { InvoiceDialogComponent } from '../../../modals/invoice-dialog/invoice-dialog.component';
import { InvoiceDetailsInterface } from 'src/app/user-management/models/invoice-details.interface';
import { CompleteModalComponent } from '../../../modals/complete-modal/complete-modal.component';
import { ConfirmAnotherCardComponent } from '../confirm-another-card/confirm-another-card.component';

@Component({
	selector: 'app-invoice-lists',
	templateUrl: './invoice-lists.component.html',
	styleUrls: ['./invoice-lists.component.scss']
})
export class InvoiceListsComponent implements OnInit {
	public invoiceHistory: any;
	public filteredRowData: any;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading: boolean = false;
	public searchFormControl: FormGroup;
	public rowDataLength = 0;

	public invoiceDetail: any;

	public columnDef: any = [];

	public pageSize: number = 25;

	constructor(
		private injector: Injector,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private sharedStore: Store<SharedState>,
		public dialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private router: Router,
		private formBuilder: FormBuilder,
		private popupDialog: PopupDialogService
	) {}

	ngOnInit(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.getInvoices();
		this.createForm();
		this.filteredData();
	}
	private createForm(): void {
		this.searchFormControl = this.formBuilder.group({
			searchForm: new FormControl(null)
		});
		this.columnDef = [
			{
				key: 'id',
				label: 'Invoice ID',
				type: DatatableEnum.none
			},
			{
				key: 'date',
				label: 'Date',
				type: DatatableEnum.none,
				options: []
			},
			{
				key: 'amount',
				label: 'Amount',
				type: DatatableEnum.none
			},
			{
				key: 'status',
				label: 'Status',
				type: DatatableEnum.alert
			},
			{
				key: 'action',
				label: 'Invoices',
				type: DatatableEnum.button,
				options: []
			}
		];
	}
	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}
	public getInvoices(): void {
		this.backOfficeService
			.getMyInvoiceHistory()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((res: any) => {
				if (res) {
					this.invoiceHistory = res;
					this.rowDataLength = this.invoiceHistory.length;
					this.filteredRowData = this.paginate(this.invoiceHistory, this.pageSize, 1);
				}

				this.loading = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
			});
	}

	public filteredData(): void {
		this.searchFormControl
			.get('searchForm')
			.valueChanges.pipe()
			.subscribe(value => {
				this.filteredRowData = this.invoiceHistory.filter(
					data =>
						data.externalTransactionId.toString().toLowerCase().includes(value.toString().toLowerCase()) ||
						data.amount.toString().includes(value.toString().toLowerCase()) ||
						data.billingId.toString().includes(value.toString().toLowerCase())
				);
			});
	}

	public onPageChanged({ oldPage, newPage }): void {
		this.filteredRowData = this.paginate(this.invoiceHistory, this.pageSize, newPage);
	}

	public paginate(arrayOfOject: any, pageSize: number, pageNumber: number): any {
		return arrayOfOject.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
	}
	public getInvoiceDetailByTransaction(transactionId: string): any {
		this.loading = false;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.getMyInvoiceByTransactionId(transactionId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(res: any) => {
					if (res) {
						const dialogRef = this.dialog.open(InvoiceDialogComponent, {
							width: '80%',
							height: '85vh',
							disableClose: true
						});

						dialogRef.componentInstance.InvoiceDetail = res;
						dialogRef.afterClosed().subscribe(() => {});
					}
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				},
				() => {}
			);
	}
	public getInvoiceDetailByBilling(billingId: string): void {
		this.loading = false;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.getMyInvoiceByBillingId(billingId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(res: any) => {
					if (res) {
						const dialogRef = this.dialog.open(InvoiceDialogComponent, {
							width: '80%',
							height: '85vh',
							disableClose: true
						});

						dialogRef.componentInstance.InvoiceDetail = res;
						dialogRef.afterClosed().subscribe(() => {});
					}
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				},
				() => {}
			);
	}
	public download(invoice: any): void {
		if (invoice.status === 'Succeeded' && invoice.chergebeeId && (invoice.chergebeeId !== undefined || invoice.chergebeeId !== '')) {
			this.popupDialog.download(invoice.chergebeeId);
		} else {
			this.innitiatedDownload(invoice);
		}
	}
	public innitiatedDownload(invoice: any) {
		const dialogRef = this.dialog.open(InvoiceDialogComponent, {
			width: '80%',
			height: '85vh',
			disableClose: true
		});

		dialogRef.componentInstance.InvoiceDetail = invoice;
		dialogRef.afterClosed().subscribe(() => {});
		//this.getInvoiceDetailByBilling(invoice.billingId);
		/*
		if (invoice.externalTransactionId) {
			this.getInvoiceDetailByTransaction(invoice.externalTransactionId);
		} else {
			this.getInvoiceDetailByBilling(invoice.billingId);
		}*/
	}

	public payForBilling(invoice: any): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.payForInvoice(invoice.billingId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res) {
						this.toastService.sendSuccessToast('Invoice Payment Successful');
						this.getInvoices();
						this.openConfirmModal();
					} else {
						this.useAnotherCardModel();
					}
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				},
				err => {
					if (
						err.error[0].code === 'Filed__Domain__NA__Billing__IsNotFoundByCriteria' ||
						err.error[0].code === 'Filed__Domain__NA__Contact__Subscription_IsInvalid'
					) {
						this.toastService.sendErrorToast('Invoice Payment Failed, Invalid Bill');
					}
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public AddCardConfirmation(): void {
		const dialogRef = this.dialog.open(ConfirmAnotherCardComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Payment failed!',
			text: 'Try adding a new card for the payment. Do you want to add a new card?',
			closeBtn: true,
			actionBtn: true,
			actionBtnText: 'ADD CARD'
		};
		dialogRef.afterClosed().subscribe(details => {
			if (details.addCard) {
				this.router.navigateByUrl('user-management/payment-and-billing/add-card?default=true');
			}
		});
	}

	public useAnotherCardModel(): void {
		const dialogRef = this.dialog.open(CompleteModalComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Payment Failed!',
			text: 'Your card failed for the last payment, please use another card by making it default payment card.',
			closeBtn: false,
			actionBtn: false
		};
		dialogRef.afterClosed().subscribe(() => {});
	}

	public openConfirmModal(): void {
		const dialogRef = this.dialog.open(CompleteModalComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Invoice Payment',
			text: 'You have successfully paid your Invoice bill.',
			closeBtn: false,
			actionBtn: false
		};
		dialogRef.afterClosed().subscribe(() => {});
	}

	public fixDate(date: string): string {
		var dates = date.split('T')[0].split('-');
		return `${dates[2]}.${dates[1]}.${dates[0]}`;
	}

	fixInvDate(dateStr: string): string {
		return moment(dateStr).format('MMMM Do YYYY').replace('undefined.undefined.', '');
	}
	public fixAmount(invoice: any): string {
		let cur = invoice?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: invoice?.currency || 'USD'
		}).format(invoice?.amount || 0);
		return cur + ' ' + amount;
	}
}
