<div class="grid-section">
	<div class="form-area">
		<div class="container">
			<ng-container *ngIf="!submitting">
				<div class="logo"><img width="40%" alt="" src="assets/logo/lolly-logo.svg" /></div>
				<div class="title">Welcome to LOLLY</div>
				<div class="sub-title">Enter your details to proceed</div>

				<div class="form margin-bottom-spacing">
					<outline-tb [control]="emailControl" [validatorMessages]="validationMessages" label="Enter your email" class="input-box"></outline-tb>
				</div>
				<div class="form-btn">
					<btn04 class="btn-signup" *ngIf="emailControl.invalid" [disabled]="emailControl.invalid">Continue</btn04>

					<btn01 class="btn-signup" (onClick)="continueSignup()" *ngIf="emailControl.valid">
						Continue
					</btn01>
				</div>

				<div class="footer left">
					Already have an account?
					<a [routerLink]="'/authentication'" class="signin">Login</a>
				</div>
			</ng-container>
			<div class="loading" *ngIf="submitting">
				<lolly-spinning-loader></lolly-spinning-loader>
			</div>
		</div>
	</div>
</div>
<app-toast-notification></app-toast-notification>
