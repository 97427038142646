<div class="grid-section">
	<div class="form-area">
		<lolly-spinning-loader class="spinner" *ngIf="submitting"></lolly-spinning-loader>
		<div class="container" *ngIf="!submitting">
			<ng-container>
				<div class="logo"><img width="40%" alt="" src="assets/logo/lolly-logo.svg" /></div>
				<div class="title">Email Verification</div>
				<div class="sub-title">
					We have sent an email to
					<span>{{ registrationDetail.email }}</span>
				</div>
				<div class="sub-title">Verifiy your account on the email link.</div>

				<div class="email-providers">
					<a href="https://mail.google.com/mail/" target="_blank" class="email-providers-label email-gmail-label">
						<div class="img-tag">
							<img src="https://cdn-icons-png.flaticon.com/512/281/281769.png" />
							Open Gmail
						</div>
					</a>
					<a href="https://outlook.office.com/mail/" target="_blank" class="email-providers-label email-outlook-label">
						<div class="img-tag">
							<img
								src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Outlook.com_icon_%282012-2019%29.svg/2028px-Outlook.com_icon_%282012-2019%29.svg.png"
							/>
							Open Outlook
						</div>
					</a>
				</div>

				<div class="footer">
					Didn't get an email? Don't worry, we get you a new one.
					<button class="link" (click)="resendVerificationEmail()">Re-send</button>
				</div>
			</ng-container>
		</div>
	</div>
</div>
<app-toast-notification></app-toast-notification>
