import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from '../../shared/state/user/user.reducer';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { EnableFirstTimeCustomer } from '../state/authentication.action';
import { AuthenticationState, getRedirectUrl } from '../state/authentication.reducer';
import { TrialDialogComponent } from '../trial-dialog/trial-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BillingPlan } from '../../user-management/models/billing-plan.interface';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { getGlobalSpinnerStatus, SharedState } from '../../shared/state/shared.reducer';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-get-started',
	templateUrl: './get-started.component.html',
	styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit, OnDestroy {
	public showSpinner: boolean;

	private unsubscriber$ = new Subject<void>();

	constructor(
		private authenticationService: AuthenticationService,
		private backOfficeService: BackOfficeService,
		private translate: TranslateService,
		private toastNotificationService: ToastNotificationService,
		private router: Router
	) {}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public getStarted(): void {
		this.router.navigate(['/auth-landing']);
	}

	public logout(): void {
		this.authenticationService.logout();
	}
}
