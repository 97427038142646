import { FormGroup } from '@angular/forms';

import { Action } from '@ngrx/store';
import {
	ConversationMessageInterface,
	InstagramConversationInterface,
	SMIConversationInterface,
	SMIConversationModel
} from 'src/app/_models/smi-conversation/conversation.interface';
import { CreativeInfluencerInterface } from '../modules/creatives/models/creative-influencer.interface';
import { fieldType } from '../modules/discovery/models/campaign-form.interface';
import { InfluencerModel } from '../modules/discovery/models/discovery-influencer-list.model';
import {
	DiscoveryFilterFormDataInterface,
	InfluencerSortInterface,
	NewSearchInfluencerFilters,
	SearchInfluencer
} from '../modules/discovery/models/influencers.interface';
import { InfluencerProfileApi } from '../modules/influencer-profile/models/influencer-profile.interface';
import { CampaignDocument } from '../modules/outreach/models/campaign-document.interface';
import { Campaign } from '../modules/outreach/models/campaign.interface';
import { OutreachInfluencerInterface } from '../modules/outreach/models/outreach-influencer.interface';

export enum SMIActionsTypes {
	// General User Campaign Data
	UserCampaigns = '[SMI] User Campaigns',
	SelectedCampaign = '[SMI] Selected Campaign',

	// Discovery Data
	LoadingDiscovery = '[SMI] Loading Discovery',
	LoadDiscoveryInfluencers = '[SMI] Load Discovery Influencers',
	LoadDiscoveryInfluencersSuccess = '[SMI] Load Discovery Influencers Success',
	LoadDiscoveryInfluencersFailure = '[SMI] Load Discovery Influencers Failure',
	SaveNextPage = '[SMI] Save Next Page Number',

	AddFilterChip = '[SMI] Add Filter',
	ResetFilterChips = '[SMI] Reset Filter Chips',
	SaveNewFilter = '[SMI] Save New Filter',
	RemoveFilter = '[SMI] Remove Filter',
	RemoveFilterChip = '[SMI] Remove Filter Chip',
	ApplyNameFilter = '[SMI] Apply Name Filter',
	ApplySortFilters = '[SMI] Apply Sort Filters',
	ActivateFilter = '[SMI] Activate Filter',

	// Outreach Data
	LoadOutreachInfluencers = '[SMI] Load Outreach Influencers',
	LoadOutreachConversations = '[SMI] Load Outreach Conversations',
	LoadOutreachFiles = '[SMI] Load Outreach Files',
	LoadOutreachFilesSuccess = '[SMI] Load Outreach Files Success',
	AddFileToOutreachList = '[SMI] Add File To Outreach List',
	RemoveFileFromOutreachList = '[SMI] Remove File From Outreach List',

	// Creatives Data
	LoadCreativesInfluencers = '[SMI] Load Creatives Influencers',
	LoadCreativesConversations = '[SMI] Load Creatives Conversations',

	// Instagram Messages
	AddInstaMessages = '[SMI] AddInstaMessages',
	AddInstaConversations = '[SMI] AddInstaConversations',
	UpdateInstagramAccountStatus = '[SMI] Validate Instagram Account',
	LoadInstagramMessages = '[SMI] Load Instagram Messages',
	LoadInstagramMessagesSuccess = '[SMI] Load Instagram Messages Success',
	LoadInstagramMessagesFailure = '[SMI] Load Instagram Messages Failure',
	UpdateSelectedInstagramConversation = '[SMI] Update Selected Instagram Conversation',
	//Influencer Profile
	AddInfluencerProfile = '[SMI] AddInfluencerProfile'
}

export class LoadUserCampaigns implements Action {
	readonly type = SMIActionsTypes.UserCampaigns;
	constructor(public payload: Campaign[]) {}
}

export class SelectCampaign implements Action {
	readonly type = SMIActionsTypes.SelectedCampaign;
	constructor(public payload: Campaign) {}
}

export class LoadingDiscovery implements Action {
	readonly type = SMIActionsTypes.LoadingDiscovery;
	constructor(public payload: boolean) {}
}

export class LoadDiscoveryInfluencers implements Action {
	readonly type = SMIActionsTypes.LoadDiscoveryInfluencers;
	constructor() {}
}

export class LoadDiscoveryInfluencersSuccess implements Action {
	readonly type = SMIActionsTypes.LoadDiscoveryInfluencersSuccess;
	constructor(public payload: InfluencerModel[]) {}
}

export class LoadDiscoveryInfluencersFailure implements Action {
	readonly type = SMIActionsTypes.LoadDiscoveryInfluencersFailure;
	constructor(public payload: string) {}
}
export class SaveNextPage implements Action {
	readonly type = SMIActionsTypes.SaveNextPage;
	constructor(public payload: number) {}
}

export class SaveNewFilter implements Action {
	readonly type = SMIActionsTypes.SaveNewFilter;
	constructor(public payload: NewSearchInfluencerFilters) {}
}

export class ActivateFilter implements Action {
	readonly type = SMIActionsTypes.ActivateFilter;
	constructor() {}
}

export class ApplyNameFilter implements Action {
	readonly type = SMIActionsTypes.ApplyNameFilter;
	constructor(public payload: string) {}
}

export class ApplySortFilters implements Action {
	readonly type = SMIActionsTypes.ApplySortFilters;
	constructor(public payload: InfluencerSortInterface[]) {}
}

export class AddFilterChip implements Action {
	readonly type = SMIActionsTypes.AddFilterChip;
	constructor(public payload: fieldType) {}
}

export class ResetFilterChips implements Action {
	readonly type = SMIActionsTypes.ResetFilterChips;
	constructor() {}
}

export class RemoveFilter implements Action {
	readonly type = SMIActionsTypes.RemoveFilter;
	constructor(public payload: fieldType) {}
}

export class RemoveFilterChip implements Action {
	readonly type = SMIActionsTypes.RemoveFilterChip;
	constructor(public payload: fieldType) {}
}

export class LoadOutreachInfluencer implements Action {
	readonly type = SMIActionsTypes.LoadOutreachInfluencers;
	constructor(public payload: OutreachInfluencerInterface[]) {}
}

export class LoadOutreachConversations implements Action {
	readonly type = SMIActionsTypes.LoadOutreachConversations;
	constructor(public payload: SMIConversationModel[]) {}
}

export class LoadOutreachFiles implements Action {
	readonly type = SMIActionsTypes.LoadOutreachFiles;
	constructor(public payload: number) {}
}

export class LoadOutreachFilesSuccess implements Action {
	readonly type = SMIActionsTypes.LoadOutreachFilesSuccess;
	constructor(public payload: CampaignDocument[]) {}
}

export class AddFileToOutreachList implements Action {
	readonly type = SMIActionsTypes.AddFileToOutreachList;
	constructor(public payload: CampaignDocument) {}
}

export class RemoveFileFromOutreachList implements Action {
	readonly type = SMIActionsTypes.RemoveFileFromOutreachList;
	constructor(public payload: CampaignDocument) {}
}
export class LoadCreativesInfluencers implements Action {
	readonly type = SMIActionsTypes.LoadCreativesInfluencers;
	constructor(public payload: CreativeInfluencerInterface[]) {}
}

export class LoadCreativeConversations implements Action {
	readonly type = SMIActionsTypes.LoadCreativesConversations;
	constructor(public payload: SMIConversationModel[]) {}
}

export class AddInstaMessages implements Action {
	readonly type = SMIActionsTypes.AddInstaMessages;
	constructor(public payload: { userId: string; message: ConversationMessageInterface; fromBrand: boolean }) {}
}

export class UpdateInstagramAccountStatus implements Action {
	readonly type = SMIActionsTypes.UpdateInstagramAccountStatus;
	constructor(public payload: boolean) {}
}

export class LoadInstagramMessages implements Action {
	readonly type = SMIActionsTypes.LoadInstagramMessages;
	constructor() {}
}
export class LoadInstagramMessagesSuccess implements Action {
	readonly type = SMIActionsTypes.LoadInstagramMessagesSuccess;
	constructor(public payload: InstagramConversationInterface[]) {}
}
export class LoadInstagramMessagesFailure implements Action {
	readonly type = SMIActionsTypes.LoadInstagramMessagesFailure;
	constructor(public payload: string) {}
}
export class UpdateSelectedInstagramConversation implements Action {
	readonly type = SMIActionsTypes.UpdateSelectedInstagramConversation;
	constructor(public payload: SMIConversationInterface) {}
}

export class AddInfluencerProfile implements Action {
	readonly type = SMIActionsTypes.AddInfluencerProfile;
	constructor(public payload: InfluencerProfileApi) {}
}

export type SMIAction =
	| LoadUserCampaigns
	| SelectCampaign
	| LoadingDiscovery
	| LoadDiscoveryInfluencers
	| LoadDiscoveryInfluencersSuccess
	| LoadDiscoveryInfluencersFailure
	| SaveNextPage
	| AddFilterChip
	| SaveNewFilter
	| ApplyNameFilter
	| ResetFilterChips
	| ApplySortFilters
	| RemoveFilter
	| ActivateFilter
	| RemoveFilterChip
	| LoadOutreachInfluencer
	| LoadOutreachConversations
	| LoadOutreachFiles
	| LoadOutreachFilesSuccess
	| AddFileToOutreachList
	| RemoveFileFromOutreachList
	| LoadCreativesInfluencers
	| LoadCreativeConversations
	| AddInstaMessages
	| UpdateInstagramAccountStatus
	| LoadInstagramMessages
	| LoadInstagramMessagesSuccess
	| LoadInstagramMessagesFailure
	| UpdateSelectedInstagramConversation
	| AddInfluencerProfile;
