import { DropdownInterface } from '@filed-com/filed-lib';
import { InfluencerCardInterface } from '@filed-com/filed-lib/lib/cards/influencer-card/models/influencer-card-interface';

export interface NewSearchInfluencerFilters {
	/*
	 *
	 * Please visit below link for details of the filters
	 * https://filed.atlassian.net/wiki/spaces/SMIPY/pages/874905601/Get+Influencer+Profiles
	 *
	 */

	// Used for searching with a name
	name?: string;

	// Used for pagination
	nextPage?: string;

	// Used for getting total count
	getTotalCount?: boolean;

	// General Parameters (these parameters are common for all influencers)
	locations?: Array<{ name: string; id: string }>;
	languages?: Array<{ name: string; id: string }>;
	tier?: string;
	lookalikes?: string;
	gender?: DropdownInterface;
	hashtags?: Array<string>;
	categories?: Array<DropdownInterface>;
	hasEmail?: boolean;
	age?: RangeInterface;
	/*
	 *
	 * Platform wise paramters
	 * (Most of them would be in range of min and max)
	 * ig - Instagram
	 * tk - TikTok
	 * yt - youtube
	 *
	 */
	ig?: PlatformFilters;
	tk?: PlatformFilters;
	yt?: PlatformFilters;

	// For sorting
	sortByFollowers?: number;
	sortByEngagementRate?: number;
	sortByAverageInteractions?: number;
}

export interface PlatformFilters {
	// Common for all platforms
	flw?: RangeInterface;
	er?: RangeInterface;
	epp?: RangeInterface;
	bk?: Array<string>;

	// Insta Specific
	iv?: boolean;
	at?: number;
	au_gender?: DropdownInterface;
	au_location?: Array<{ name: string; id: string }>;
	au_age?: RangeInterface;
	au_language?: Array<{ name: string; id: string }>;
	au_interests?: Array<DropdownInterface>;
	// Youtube specific
	vw?: RangeInterface;
	avpp?: RangeInterface;
	pf?: RangeInterface;
}

export interface RangeInterface {
	min?: DropdownInterface;
	max?: DropdownInterface;
}
export interface CreditInterface {
	Available_Influencer_Search_Credit: number;
	Offered_Influencer_Search_Credit: number;
}

export enum FilterTypeEnum {
	LOCATIONS = <any>'Location',
	LANGUAGES = <any>'Language',
	GENDER = <any>'Gender',
	TIER = <any>'Tier',
	HASHTAGS = <any>'Hashtag',
	CATEGORIES = <any>'Interest',
	HASEMAIL = <any>'Has Email',
	AGE = <any>'Age',
	IG = <any>'IG',
	TK = <any>'TK',
	YT = <any>'YT',
	AU_GENDER = <any>'Audience Gender',
	AU_LOCATION = <any>'Audience Location',
	AU_AGE = <any>'Audience Age',
	AU_LANGUAGE = <any>'Audience Language',
	AU_INTERESTS = <any>'Audience Interests',
	FLW = <any>'Followers',
	// MINFOLLOWERS = <any>'Followers',
	// MAXFOLLOWERS = <any>'Followers',
	ER = <any>'Engagement Rate',
	EPP = <any>'Average Interactions',
	// MINENGAGEMENTS = <any>'Average Interactions',
	// MAXENGAGEMENTS = <any>'Average Interactions',
	BK = <any>'Bio Keyword',
	IV = <any>'Is Verified',
	AT = <any>'Account Type',
	VW = <any>'Overall Views',
	AVPP = <any>'Average Views Per Post',
	PF = <any>'Post Frequency'
}

export enum InfluencerGenderEnum {
	female = <any>'Women',
	male = <any>'Men'
}

export enum InfluecnerVerifiedEnum {
	true = <any>'Yes',
	false = <any>'No'
}

export enum InfluencerEmailEnum {
	YES = 'Yes',
	NO = 'No'
}

export interface SearchInfluencer {
	name?: string;
	nextPage?: string | number;
	platforms?: string;
	pageSize?: number;
	get_total_count?: boolean;
	is_verified?: boolean;
	account_type?: number;
	locations?: string[];
	hashtags?: string[];
	bioWords?: string[];
	languages?: string[];
	categories?: string[];
	gender?: string;
	has_email?: boolean;
	followers_min_count?: number;
	followers_max_count?: number;
	engagements_min_count?: number;
	engagements_max_count?: number;
	sort_by_followers?: number;
	sort_by_engagement_rate?: number;
	age_max_count?: number;
	age_min_count?: number;
	engagement_rate_max_count?: number;
	engagement_rate_min_count?: number;
	engagements_per_post_max_count?: number;
	engagements_per_post_min_count?: number;
	post_frequency_min_count?: number;
	post_frequency_max_count?: number;
	views_min_count?: number;
	views_max_count?: number;
	avg_views_per_post_min_count?: number;
	avg_views_per_post_max_count?: number;
}

export interface DiscoveryFilterFormDataInterface {
	minFollowers: string;
	maxFollowers: string;
	minEngagements: string;
	maxEngagements: string;
	accountType: string;
	bioKeywords: string;
	relevantTags: string;
	minEngmntRate: string;
	maxEngmntRate: string;
	location: string;
	language: string;
	contentTags: string;
	hasEmail: string;
	gender: string;
	topicInterest: string;
	brands: string;
	varifiedInfluencer: string;
	ageMin: string;
	ageMax: string;
	audlocation: string;
	audlanguage: string;
	hsIncomeMin: string;
	hsIncomeMax: string;
	audGender: string;
	audInterest: string;
	ethnicity: string;
	audAgeMin: string;
	audAgeMax: string;

	tiktokminFollowers: string;
	tiktokmaxFollowers: string;
	tiktokminEngagements: string;
	tiktokmaxEngagements: string;
	tiktokminEngmntRate: string;
	tiktokmaxEngmntRate: string;
	tiktokbioKeywords: string;
	tiktokrelevantTags: string;

	youtubeminFollowers: string;
	youtubmaxFollowers: string;
	youtubminEngagements: string;
	youtubmaxEngagements: string;
	youtubminEngmntRate: string;
	youtubmaxEngmntRate: string;
	youtubrelevantTags: string;
	minOverallViews: string;
	maxOverallViews: string;
	minAverageViews: string;
	maxAverageViews: string;
	minPostfrequency: string;
	maxPostfrequency: string;
}

export interface InfluencerCardInterfaceClone extends InfluencerCardInterface {
	selected: boolean;
}

export interface InfluencerSortInterface {
	name: string;
	value: number;
}

export enum InfluencerSortEnum {
	ASCENDING = 1,
	DESCENDING = -1,
	NONE = 0
}

export enum InfluencerSortByEnum {
	FOLLOWERS = 'Followers',
	ENGAGEMENT_RATE = 'Engagement Rate',
	AVERAGE_INTERACTION = 'Average Interaction'
}
