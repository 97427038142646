import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'limitLength'
})
export class LimitLengthPipe implements PipeTransform {
	public transform(value: string, limit: number, ellipsis: string): string {
		if (value.length > limit) {
			return `${value.substr(0, limit)}${ellipsis}`;
		}
		return value;
	}
}
