import { initialSharedState, sharedReducer, SharedState } from '../shared/state/shared.reducer';
import { initialReportingState, reportingReducer, ReportingState } from '../reports/state/reporting.reducer';
import { initialPixelState, pixelReducer, PixelState } from '../pixel/state/pixel.reducer';
import { authenticationReducer, AuthenticationState, initialAuthState } from '../authentication/state/authentication.reducer';
import { initialUserState, userReducer, UserState } from '../shared/state/user/user.reducer';
import { initialUserManagementState, userManagementReducer, UserManagementState } from '../user-management/state/user-management.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AppAction, AppTypes } from './app.actions';
import { adsManagerReducer, AdsManagerState, initialAdsManagerState } from '../ads-manager/state/ads-manager.reducer';
import { campaignSmartCreateReducer, CampaignSmartCreateState, initialCampaignSmartCreateState } from '../campaign-smart-create/state/smart-create.reducer';
import { CampaignCommonReducer, CampaignCommonState, initialCampaignCommonState } from './campaign-common-state/campaign-common-reducer';
import { creativeBuilderReducer, CreativeBuilderState, initialCreativeBuilderState } from '../creative-builder/state/creative-builder.reducer';
import { aaaReducer, AAAState, initialAAAState } from '../AAA/state/aaa.reducer';
import { initialSMIState, smiReducer, SMIState } from '../social-media-influencer/state/smi.reducer';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer');

export interface AppState {
	userManagement: UserManagementState;
	shared: SharedState;
	reporting: ReportingState;
	pixel: PixelState;
	authentication: AuthenticationState;
	user: UserState;
	adsManager: AdsManagerState;
	campaignSmartCreate: CampaignSmartCreateState;
	campaignShared: CampaignCommonState;
	creativeBuilder: CreativeBuilderState;
	smi: SMIState;
}

export const initialAppState: AppState = {
	userManagement: initialUserManagementState,
	shared: initialSharedState,
	reporting: initialReportingState,
	pixel: initialPixelState,
	authentication: initialAuthState,
	user: initialUserState,
	campaignSmartCreate: initialCampaignSmartCreateState,
	adsManager: initialAdsManagerState,
	campaignShared: initialCampaignCommonState,
	creativeBuilder: initialCreativeBuilderState,
	smi: initialSMIState
};

export const reducers: ActionReducerMap<AppState> = {
	userManagement: userManagementReducer,
	shared: sharedReducer,
	reporting: reportingReducer,
	pixel: pixelReducer,
	authentication: authenticationReducer,
	user: userReducer,
	campaignSmartCreate: campaignSmartCreateReducer,
	adsManager: adsManagerReducer,
	campaignShared: CampaignCommonReducer,
	creativeBuilder: creativeBuilderReducer,
	smi: smiReducer
};

export enum AppStateSlices {
	UserManagement = 'UserManagement',
	Shared = 'Shared',
	Reporting = 'Reporting',
	Pixel = 'Pixel',
	Authentication = 'Authentication',
	User = 'User',
	Accounts = 'Accounts',
	CampaignShared = 'CampaignShared',
	AdsManager = 'AdsManager',
	CreativeBuilder = 'CreativeBuilder',
	SMI = 'SMI'
}

export function clearStateMetaReducer(reducer: any) {
	return function (state: AppState, action: AppAction) {
		if (action.type === AppTypes.ResetAppState) {
			state = initialAppState;
		}

		return reducer(state, action);
	};
}
