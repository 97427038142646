import _ from 'lodash';
import { DataInterface, NullDataInterface } from '../../shared/state/state-entities/data-interface.model';
import { Campaign } from '../modules/outreach/models/campaign.interface';
import { ConversationMessageInterface, InstagramConversationInterface, SMIConversationModel } from '../../_models/smi-conversation/conversation.interface';
import { InfluencerModel } from '../modules/discovery/models/discovery-influencer-list.model';
import { CampaignDocument } from '../modules/outreach/models/campaign-document.interface';
import { CreativeInfluencerInterface } from '../modules/creatives/models/creative-influencer.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SMIAction, SMIActionsTypes } from './smi.actions';
import { OutreachInfluencerInterface } from '../modules/outreach/models/outreach-influencer.interface';
import { FilterTypeEnum, NewSearchInfluencerFilters, InfluencerSortByEnum } from '../modules/discovery/models/influencers.interface';
import { fieldType } from '../modules/discovery/models/campaign-form.interface';
import { InfluencerProfileApi } from '../modules/influencer-profile/models/influencer-profile.interface';

export interface SMIState {
	// Campaigns
	userCampaigns: DataInterface<Campaign[]>;
	selectedCampaign: Campaign;

	// Discovery Data
	loadingDiscovery: boolean;
	discoveryInfluencers: DataInterface<InfluencerModel[]>;
	newDiscoveryFilters: NewSearchInfluencerFilters;
	filterChips: Array<fieldType>;
	isFilterActive: boolean;
	nextPage: number | string;

	// Outreach Data
	outreachInfluencers: DataInterface<OutreachInfluencerInterface[]>;
	outreachConversations: DataInterface<SMIConversationModel[]>;
	outreachFiles: DataInterface<CampaignDocument[]>;

	// Creatives Data
	creativeInfluencers: DataInterface<CreativeInfluencerInterface[]>;
	creativesConversations: DataInterface<SMIConversationModel[]>;

	instagramConnected: boolean;
	instagramMessages: DataInterface<InstagramConversationInterface[]>;
	influencerProfile: InfluencerProfileApi;
}

export const initialSMIState: SMIState = {
	userCampaigns: _.cloneDeep(NullDataInterface),
	selectedCampaign: null,
	loadingDiscovery: false,
	discoveryInfluencers: _.cloneDeep(NullDataInterface),
	newDiscoveryFilters: {},
	filterChips: [],
	isFilterActive: false,
	nextPage: -1,
	outreachInfluencers: _.cloneDeep(NullDataInterface),
	outreachConversations: _.cloneDeep(NullDataInterface),
	outreachFiles: _.cloneDeep(NullDataInterface),
	creativeInfluencers: _.cloneDeep(NullDataInterface),
	creativesConversations: _.cloneDeep(NullDataInterface),
	instagramConnected: null,
	instagramMessages: _.cloneDeep(NullDataInterface),
	influencerProfile: null
};

const getSMIState = createFeatureSelector<SMIState>('smi');

export const getUserCampaigns = createSelector(getSMIState, state => state.userCampaigns);
export const getSelectedCampaign = createSelector(getSMIState, state => state.selectedCampaign);
export const getInstagramMessages = createSelector(getSMIState, state => state.instagramMessages);
export const getInstagramLoad = createSelector(getSMIState, state => {
	return [state.instagramMessages.isLoading, state.instagramMessages.isLoaded];
});
export const getInstagramConversations = createSelector(getSMIState, state => {
	if (state.instagramMessages.isLoaded) return state.instagramMessages.data;
	return null;
});
export const getSelectedInstagramConversation = createSelector(getSMIState, state => {
	if (!state.instagramMessages.isLoaded) return null;
	return state.instagramMessages.data.find(x => x.selected);
});
export const getInstagramStatus = createSelector(getSMIState, state => state.instagramConnected);
export const getInfluencerProfile = createSelector(getSMIState, state => state.influencerProfile);

export const getCampaignDropdownListData = createSelector(
	getUserCampaigns,
	getSelectedCampaign,
	(campaigns: DataInterface<Campaign[]>, selectedCampaign: Campaign) => {
		if (!campaigns.isLoaded || !selectedCampaign) return;

		return campaigns.data.map(x => {
			return {
				id: x.id,
				name: x.name,
				currency: x.currency.symbol,
				description: x.description
			};
		});
	}
);
export const getDiscoveryInfluencers = createSelector(getSMIState, state => state.discoveryInfluencers);
export const getNextPage = createSelector(getSMIState, state => state.nextPage);
export const getLoadingDisocvery = createSelector(getSMIState, state => state.loadingDiscovery);
export const getNewFilters = createSelector(getSMIState, state => state.newDiscoveryFilters);
export const getFilterChips = createSelector(getSMIState, state => state.filterChips);
export const isFilterActive = createSelector(getSMIState, state => state.isFilterActive);
export const getOutreachInfluencers = createSelector(getSMIState, state => state.outreachInfluencers);
export const getOutreachConversations = createSelector(getSMIState, state => state.outreachConversations);
export const getOutreachFiles = createSelector(getSMIState, state => state.outreachFiles);
export const getCreativeInfluencers = createSelector(getSMIState, state => state.creativeInfluencers);
export const getCreativeConsversations = createSelector(getSMIState, state => state.creativesConversations);

export function smiReducer(state: SMIState = initialSMIState, action: SMIAction): SMIState {
	switch (action.type) {
		case SMIActionsTypes.UserCampaigns: {
			return {
				...state,
				userCampaigns: {
					...state.userCampaigns,
					isLoaded: true,
					data: action.payload
				}
			};
		}

		case SMIActionsTypes.SelectedCampaign: {
			return {
				...state,
				selectedCampaign: action.payload
			};
		}

		case SMIActionsTypes.LoadingDiscovery: {
			return {
				...state,
				loadingDiscovery: action.payload
			};
		}

		case SMIActionsTypes.LoadDiscoveryInfluencers: {
			return {
				...state,
				discoveryInfluencers: {
					...state.discoveryInfluencers,
					isLoaded: state.discoveryInfluencers.data !== null,
					isLoading: state.discoveryInfluencers.data == null
				}
			};
		}

		case SMIActionsTypes.LoadDiscoveryInfluencersSuccess: {
			return {
				...state,
				discoveryInfluencers: {
					...state.discoveryInfluencers,
					isLoading: false,
					isLoaded: true,
					data: action.payload
				}
			};
		}

		case SMIActionsTypes.LoadDiscoveryInfluencersFailure: {
			return {
				...state,
				discoveryInfluencers: {
					...state.discoveryInfluencers,
					isLoaded: false,
					isLoading: false,
					errorCode: action.payload
				},
				loadingDiscovery: false
			};
		}

		case SMIActionsTypes.SaveNewFilter: {
			if (state.newDiscoveryFilters !== action.payload) {
				if (Object.keys(action.payload).length == 0)
					return {
						...state,
						newDiscoveryFilters: {}
					};
				return {
					...state,
					discoveryInfluencers: {
						...state.discoveryInfluencers,
						isLoaded: false
					},
					newDiscoveryFilters: {
						...state.newDiscoveryFilters,
						locations: action.payload.locations,
						languages: action.payload.languages,
						categories: action.payload.categories,
						lookalikes: '',
						tier: action.payload.tier,
						gender: action.payload.gender,
						age: action.payload.age,
						hashtags: action.payload.hashtags,
						hasEmail: action.payload.hasEmail,
						ig: action.payload.ig,
						tk: action.payload.tk,
						yt: action.payload.yt
					},
					isFilterActive: true
				};
			}
			return state;
		}
		case SMIActionsTypes.ActivateFilter: {
			return {
				...state,
				isFilterActive: true
			};
		}

		case SMIActionsTypes.ApplyNameFilter: {
			return {
				...state,
				discoveryInfluencers: {
					...state.discoveryInfluencers,
					isLoaded: false
				},
				newDiscoveryFilters: {
					...state.newDiscoveryFilters,
					name: action.payload
				}
			};
		}

		case SMIActionsTypes.ApplySortFilters: {
			return {
				...state,
				discoveryInfluencers: {
					...state.discoveryInfluencers,
					isLoaded: false
				},
				newDiscoveryFilters: {
					...state.newDiscoveryFilters,
					sortByEngagementRate: action.payload.find(x => x.name === InfluencerSortByEnum.ENGAGEMENT_RATE).value,
					sortByFollowers: action.payload.find(x => x.name === InfluencerSortByEnum.FOLLOWERS).value,
					sortByAverageInteractions: action.payload.find(x => x.name === InfluencerSortByEnum.AVERAGE_INTERACTION).value
				}
			};
		}

		case SMIActionsTypes.AddFilterChip: {
			return {
				...state,
				filterChips: [...state.filterChips, action.payload]
			};
		}
		case SMIActionsTypes.ResetFilterChips: {
			return {
				...state,
				filterChips: []
			};
		}

		case SMIActionsTypes.RemoveFilterChip: {
			return {
				...state,
				filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
			};
		}

		case SMIActionsTypes.RemoveFilter: {
			let parent: string, child: string;
			let idFilters = action.payload.id.split('_');
			parent = FilterTypeEnum[idFilters[0]].toLowerCase();
			let isPlatfrom = parent == 'ig' || parent == 'yt' || parent == 'tk';
			let platformKeys = ['flw', 'er', 'epp', 'pf', 'avpp', 'au_age'];
			if (isPlatfrom) {
				child = FilterTypeEnum[idFilters[1]]?.toLowerCase();
				if (platformKeys.includes(child))
					return {
						...state,
						discoveryInfluencers: {
							...state.discoveryInfluencers,
							isLoaded: false
						},
						newDiscoveryFilters: {
							...state.newDiscoveryFilters,
							[parent]: {
								...state.newDiscoveryFilters[parent],
								[child]: {
									min: null,
									max: null
								}
							}
						},
						filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
					};
				if (child == 'bk') {
					let value = action.payload.name.split(':')[1].trim();
					return {
						...state,
						discoveryInfluencers: {
							...state.discoveryInfluencers,
							isLoaded: false
						},
						newDiscoveryFilters: {
							...state.newDiscoveryFilters,
							[parent]: {
								...state.newDiscoveryFilters[parent],
								[child]: [...state.newDiscoveryFilters[parent][child].filter(x => x != value)]
							}
						},
						filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
					};
				}

				return {
					...state,
					discoveryInfluencers: {
						...state.discoveryInfluencers,
						isLoaded: false
					},
					newDiscoveryFilters: {
						...state.newDiscoveryFilters,
						[parent]: {
							...state.newDiscoveryFilters[parent],
							[child]: null
						}
					},
					filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
				};
			}
			if (parent == 'locations' || parent == 'languages') {
				child = idFilters[1];
				let newArray = [...state.newDiscoveryFilters[parent].filter(x => x.id != child)];
				return {
					...state,
					discoveryInfluencers: {
						...state.discoveryInfluencers,
						isLoaded: false
					},
					newDiscoveryFilters: {
						...state.newDiscoveryFilters,
						[parent]: newArray
					},
					filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
				};
			}

			if (parent == 'categories') {
				return {
					...state,
					discoveryInfluencers: {
						...state.discoveryInfluencers,
						isLoaded: false
					},
					newDiscoveryFilters: {
						...state.newDiscoveryFilters,
						categories: [...state.newDiscoveryFilters[parent].filter(x => x.id != +idFilters[1])]
					},
					filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
				};
			}

			if (parent == 'age') {
				return {
					...state,
					discoveryInfluencers: {
						...state.discoveryInfluencers,
						isLoaded: false
					},
					newDiscoveryFilters: {
						...state.newDiscoveryFilters,
						[parent]: {
							min: null,
							max: null
						}
					},
					filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
				};
			}
			if (parent == 'hashtags') {
				let value = action.payload.name.split(':')[1].trim();
				return {
					...state,
					discoveryInfluencers: {
						...state.discoveryInfluencers,
						isLoaded: false
					},
					newDiscoveryFilters: {
						...state.newDiscoveryFilters,
						hashtags: [...state.newDiscoveryFilters[parent].filter(x => x != value)]
					},
					filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
				};
			}
			if (parent == 'hasemail') {
				return {
					...state,
					discoveryInfluencers: {
						...state.discoveryInfluencers,
						isLoaded: false
					},
					newDiscoveryFilters: {
						...state.newDiscoveryFilters,
						hasEmail: null
					},
					filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
				};
			}
			return {
				...state,
				discoveryInfluencers: {
					...state.discoveryInfluencers,
					isLoaded: false
				},
				newDiscoveryFilters: {
					...state.newDiscoveryFilters,
					[parent]: null
				},
				filterChips: [...state.filterChips.filter(x => x.id != action.payload.id)]
			};
		}

		case SMIActionsTypes.SaveNextPage: {
			return {
				...state,
				nextPage: action.payload
			};
		}

		case SMIActionsTypes.LoadOutreachInfluencers: {
			return {
				...state,
				outreachInfluencers: {
					...state.outreachInfluencers,
					data: action.payload,
					isLoaded: true
				}
			};
		}

		case SMIActionsTypes.LoadOutreachConversations: {
			return {
				...state,
				outreachConversations: {
					...state.outreachConversations,
					data: action.payload,
					isLoaded: true
				}
			};
		}

		case SMIActionsTypes.LoadOutreachFiles: {
			return {
				...state,
				outreachFiles: {
					...state.outreachFiles,
					isLoading: true,
					isLoaded: false
				}
			};
		}

		case SMIActionsTypes.LoadOutreachFilesSuccess: {
			return {
				...state,
				outreachFiles: {
					...state.outreachFiles,
					isLoading: false,
					isLoaded: true,
					data: action.payload
				}
			};
		}

		case SMIActionsTypes.AddFileToOutreachList: {
			return {
				...state,
				outreachFiles: {
					...state.outreachFiles,
					data: [...state.outreachFiles.data, action.payload]
				}
			};
		}

		case SMIActionsTypes.RemoveFileFromOutreachList: {
			let index = state.outreachFiles.data.indexOf(action.payload);
			return {
				...state,
				outreachFiles: {
					...state.outreachFiles,
					data: [...state.outreachFiles.data.slice(0, index), ...state.outreachFiles.data.slice(index + 1)]
				}
			};
		}

		case SMIActionsTypes.LoadCreativesConversations: {
			return {
				...state,
				creativesConversations: {
					...state.creativesConversations,
					data: action.payload,
					isLoaded: true
				}
			};
		}

		case SMIActionsTypes.LoadCreativesInfluencers: {
			return {
				...state,
				creativeInfluencers: {
					...state.creativeInfluencers,
					data: action.payload,
					isLoaded: true
				}
			};
		}

		case SMIActionsTypes.UpdateInstagramAccountStatus: {
			return {
				...state,
				instagramConnected: action.payload
			};
		}

		case SMIActionsTypes.LoadInstagramMessages: {
			return {
				...state,
				instagramMessages: {
					...state.instagramMessages,
					isLoaded: false,
					isLoading: true
				}
			};
		}
		case SMIActionsTypes.LoadInstagramMessagesSuccess: {
			return {
				...state,
				instagramMessages: {
					...state.instagramMessages,
					isLoading: false,
					isLoaded: true,
					data: action.payload
				}
			};
		}
		case SMIActionsTypes.LoadInstagramMessagesFailure: {
			return {
				...state,
				instagramMessages: {
					...state.instagramMessages,
					isLoaded: false,
					isLoading: false,
					errorCode: action.payload
				}
			};
		}

		case SMIActionsTypes.AddInstaMessages: {
			let shallowCopy = [...state.instagramMessages.data];
			let foundIndex = shallowCopy.find(x => x.influencer.id === action.payload.userId);
			if (foundIndex) {
				foundIndex.messages = [...foundIndex.messages, action.payload.message];
				foundIndex.unread = !action.payload.fromBrand;
				return {
					...state,
					instagramMessages: {
						...state.instagramMessages,
						data: [foundIndex, ...state.instagramMessages.data.filter(x => x.influencer.id !== foundIndex.influencer.id)]
					}
				};
			}
			return state;
		}

		case SMIActionsTypes.UpdateSelectedInstagramConversation: {
			return {
				...state,
				instagramMessages: {
					...state.instagramMessages,
					data: state.instagramMessages.data.map(x => {
						return {
							...x,
							selected: x.influencer.userName === action.payload.user.name
						};
					})
				}
			};
		}
		case SMIActionsTypes.AddInfluencerProfile: {
			return {
				...state,
				influencerProfile: action.payload
			};
		}

		default:
			return state;
	}
}
