import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { User } from '../../../_models/user-models/user';
import { UserServiceApi } from '../../user/user.api.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';
import { select, Store } from '@ngrx/store';
import { SharedState } from '../../../shared/state/shared.reducer';
import { LoadFiledUser } from '../../../user-management/state/user-management.actions';
import { getSelectedUser } from '../../../user-management/state/user-management.reducer';
import { catchError, filter, take, tap } from 'rxjs/operators';

@Injectable()
export class UpdateUserResolver implements Resolve<User> {
	private userId: number;
	private user: Observable<User>;

	constructor(
		private router: Router,
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService,
		private sharedStore: Store<SharedState>
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
		this.userId = parseInt(route.params.userId);
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.sharedStore.pipe(select(getSelectedUser)).subscribe(user => {
			if (!user) {
				this.sharedStore.dispatch(new LoadFiledUser({ id: this.userId, setSelectedUser: true, isBackoffice: false }));
			}
		});

		return this.sharedStore.pipe(
			select(getSelectedUser),
			filter(user => user != null),
			take(1),
			tap(user => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				if (!user) {
					this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
					this.router.navigate(['/auth-landing']);
					return EMPTY;
				}
			}),
			catchError(() => {
				this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
				this.router.navigate(['/auth-landing']);
				return EMPTY;
			})
		);
	}
}
