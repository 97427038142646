import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class ChatIconSubjectService {
	public HoldIconState = new BehaviorSubject<boolean>(true);
	public castIconState = this.HoldIconState.asObservable();

	constructor() {}

	public recieveIconState(state: boolean): void {
		this.HoldIconState.next(state);
	}
}
