<div class="grid-section">
	<div class="spinner" *ngIf="submitting">
		<lolly-spinning-loader></lolly-spinning-loader>
	</div>
	<div class="form-area" *ngIf="!submitting">
		<div class="container">
			<div class="body-con">
				<button mat-button class="ml-n20" *ngIf="paymentModeSelected" (click)="deselectMode()">
					<mat-icon>chevron_left</mat-icon>
					Back
				</button>
				<div class="title">Order Summary</div>
				<div class="plan">Lolly Plan - {{ planDetails?.subscriptionName }}</div>
				<div class="logo"><img src="assets/images/signup/flash-icon.svg" /></div>

				<div class="price">
					<div class="title">Total due today</div>
					<div class="amount">{{ getPlanAmount }}</div>
				</div>
				<div class="duration">
					<div class="remark">Billed {{ planDetails?.billingInterval }}ly.</div>
					<div class="sub-title">We will bill you every {{ planDetails?.billingInterval }} on the 1st, unless you cancel.</div>
					<div class="sub-title2">Your next bill will be on the {{ planDetails?.nextInvoiceDate }}</div>
				</div>
			</div>
			<div class="footer-link">
				Got a question? &nbsp;
				<a href="https://www.filed.com/contact-us-filed/">Don't worry. We are here to help you.</a>
			</div>
		</div>
		<div class="right-pane w-800px d-flex d-align-items-c justify-content-center" *ngIf="!paymentModeSelected">
			<div class="d-flex d-j-c-flex-column d-align-items-c justify-content-center mt-n50px">
				<font04>Select a payment method</font04>
				<div class="payment-mode wallactvie d-flex d-align-items-c justify-content-between mt-24px" (click)="selectMode('BlueSnap')">
					<font06>Debit/Credit Card</font06>
					<div class="d-flex d-align-items-c">
						<img alt="Visa Card" class="visa" src="assets/icons/Visa.svg" />
						<img alt="Master Card" class="mastercard ml-10px" src="assets/icons/Master.svg" />
						<img alt="Amex Card" class="amex ml-10px" src="assets/icons/amex-card.svg" />
					</div>
				</div>
				<div class="payment-mode wallactvie d-flex d-align-items-c justify-content-center mt-16px" (click)="selectMode('PayPal')">
					<img class="visa" src="assets/icons/pp-logo.png" height="25px" />
				</div>
			</div>
		</div>
		<div class="right-pane" *ngIf="paymentModeSelected">
			<div class="inner-div">
				<div class="payment-link">
					Enter payment below, and we good to go!
				</div>
				<div class="mobile-ruler">
					<div class="ruler"></div>
					<div class="content">Enter Payment Details</div>
					<div class="ruler"></div>
				</div>
				<div class="title">Enter Payment Detail</div>

				<div class="card-image" *ngIf="paymentWallType == 'PayPal'">
					<img class="visa" src="assets/icons/pp-logo.png" height="25px" />
				</div>
				<div class="form">
					<!--to hide -->
					<div class="perrows" *ngIf="paymentWallType == 'BlueSnap'">
						<div class="column">
							<div class="card-image" *ngIf="paymentWallType == 'BlueSnap'">
								<img alt="" class="visa" src="assets/icons/Visa.svg" />
								<img alt="" class="mastercard" src="assets/icons/Master.svg" />
								<img alt="Amex Card" class="amex" src="assets/icons/amex-card.svg" />
							</div>
							<div>
								<outline-tb [control]="cardDetails.controls['cardName']" label="Enter name as seen on card" class="input-box"></outline-tb>
							</div>
						</div>
						<div class="column">
							<div class="card-holder">
								<div class="ccn">
									<div class="form-control" [control]="cardDetails.controls['cardNumber']" id="card-number" data-bluesnap="ccn"></div>
									<div id="card-logo" class="input-group-addon">
										<img alt="" src="https://files.readme.io/d1a25b4-generic-card.png" height="20px" />
									</div>
								</div>
								<span class="helper-text" id="ccn-help"></span>
							</div>
							<div class="perrows mt-10px">
								<div class="columnp expiry">
									<div class="exp" [control]="cardDetails.controls['expirationDate']" id="exp-date" data-bluesnap="exp"></div>
									<span class="helper-text" id="exp-help"></span>
								</div>
								<div class="columnp cvv">
									<div class="CVV" [control]="cardDetails.controls['securityCode']" data-bluesnap="cvv"></div>
									<span class="helper-text" id="cvv-help"></span>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div class="sub-title billing-title">Billing details & address</div>
						<div class="perrows">
							<div class="column">
								<outline-tb
									[control]="cardDetails.controls['firstAddress']"
									label="Enter first line of your address*"
									class="input-box"
								></outline-tb>
							</div>
							<div class="column">
								<outline-tb
									[control]="cardDetails.controls['secondAddress']"
									label="Enter second line of your address"
									class="input-box"
								></outline-tb>
							</div>
						</div>
						<div class="perrows">
							<div class="column">
								<outline-tb [control]="cardDetails.controls['city']" label="Enter your City*" class="input-box"></outline-tb>
							</div>
							<div class="column">
								<outline-tb [control]="cardDetails.controls['zipCode']" label="Enter your zipcode/postcode*" class="input-box"></outline-tb>
							</div>
						</div>
						<div class="perrows">
							<div class="column">
								<app-country
									[dropDownControl]="cardDetails.controls['country']"
									[placeholderText]="'Select country*'"
									[showCaretIcon]="true"
									[dropDownData]="countryData"
									[allowFilter]="true"
									(onItemSelected)="dropDownValueChanges($event)"
								></app-country>
							</div>
							<div class="column"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-btn">
				<div>
					<label>
						<input type="checkbox" [checked]="hasUserAgreed ? 'checked' : ''" (change)="onMarkDefault($event.target.checked)" />
						<span>
							Agree to
							<a (click)="openPrivacyPolicy()">terms of services</a>
							and
							<a (click)="openPrivacyPolicy()">privacy policy.</a>
						</span>
					</label>
				</div>
				<div>
					<button
						class="btn-signup"
						(click)="continueSignup()"
						[class.content-valid]="cardDetails.valid && hasUserAgreed"
						data-bluesnap="subitButton"
					>
						Pay
					</button>
				</div>
			</div>
			<div class="footer-link">
				Got a question?
				<a href="https://www.filed.com/contact-us-filed/">Don't worry. We here to help you</a>
			</div>
		</div>
	</div>
</div>
<app-toast-notification></app-toast-notification>
