import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HideGlobalSpinner, LoadAdsManagerSettings, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';

@Component({
	selector: 'app-knowledgebase',
	templateUrl: './knowledgebase.component.html',
	styleUrls: ['./knowledgebase.component.scss']
})
export class KnowledgebaseComponent implements OnInit {
	public kBaseUrl = 'https://knowledge.filed.com/';
	public kBaseIFrame = `<iframe width="100%" height="100%" src="${this.kBaseUrl}"></iframe>`;

	public homeVisited = false;
	constructor(
		public dialogRef: MatDialogRef<KnowledgebaseComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public dataFromModal: any,
		public router: Router,
		public kbService: KnowledgebaseService
	) {}

	public ngOnInit(): void {}

	public cancelModal(): void {
		this.dialogRef.close({ data: false });
	}

	public onActivate(event: any): void {}

	public ngOnDestroy(): void {}
}
