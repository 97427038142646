import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AccountDropdownComponent } from './header-account-dropdown/account-dropdown.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { DropDownButtonComponent } from './drop-down-button/drop-down-button.component';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleInsightsMetadataService } from './services/google-insights-metadata.service';
import { SettingsComponent } from './settings/settings.component';
import { WidgetLoaderComponent } from './widget-loader/widget-loader.component';
import { AssetsGalleryComponent } from './assets-gallery/assets-gallery.component';
import { UploadComponent } from './assets-gallery/components/upload/upload.component';
import { GalleryMatTabComponent } from './assets-gallery/components/gallery-mat-tab/gallery-mat-tab.component';
import { TreeChecklistComponent } from './tree-checklist/tree-checklist.component';
import { HourSelectorModule } from './hour-selector/hour-selector.module';
import { FilterTextPipe } from './pipes/filterText';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleChartsModule } from 'angular-google-charts';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { BusinessOwnerService } from '../_services/facebook-accounts/business-owner.service';
import { AdBlockerComponent } from './ad-blocker/ad-blocker.component';
import { RoundedImageContainerComponent } from './rounded-image-container/rounded-image-container.component';
import { ContainsPipe } from './pipes/contains';
import { WarningMessageNoDataComponent } from './warning-message-no-data/warning-message-no-data.component';
import { ChartsComponent } from './charts/charts.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SpinnerFiledComponent } from './spinner-filed/spinner-filed.component';
import { SpinnerComponent } from './spinner-filed/spinner.component';
import { FilterTabListComponent } from './filter-tab-list/filter-tab-list.component';
import { GenericSearchComponent } from './generic-search/generic-search.component';
import { GenericChartsComponent } from './charts2/generic-charts.component';
import { CamelCaseToTextPipe } from './pipes/humanize';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { RouterModule } from '@angular/router';
import {
	FontsModule,
	CheckboxesModule,
	TextAreasModule,
	ButtonsModule,
	DropdownsModule,
	TooltipsModule,
	TextBoxesModule,
	TreeViewsModule,
	LoadersModule,
	GraphsModule,
	NumericTextboxesModule,
	ToggleModule
} from '@filed-com/filed-lib';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AgGridModule } from 'ag-grid-angular';
import { GridsterModule } from 'angular-gridster2';
import { Ng5SliderModule } from 'ng5-slider';
import { CardBoxComponent } from '../campaign-smart-create/components-pieces/card-box/card-box.component';
import { ShareDashboardAsLinkComponent } from '../reports/components/share-dashboard-as-link/share-dashboard-as-link.component';
import { DashboardTemplateResolver } from '../reports/resolvers/dashboard-template.resolver';
import { ReportingResolver } from '../reports/resolvers/reporting.resolver';
import { DashboardGridComponent } from '../reports/shared/widget-grid/dashboard-grid.component';
import { ManageAdAccountPermissionsComponent } from '../user-management/components/account/facebook-inputs/ad-account-inputs/manage-ad-account-permissions.component';
import { DragAndDropDirective } from '../user-management/directives/drag-and-drop.directive';
import { DragDropDirective } from '../_directives/drag-drop.directive';
import { UserServiceApi } from '../_services/user/user.api.service';
import { NoResourceComponent } from './404/no-resource.component';
import { AdsManagerNameEditComponent } from './ag-grid-custom-cell-edit-renderers/ads-manager-name-edit/ads-manager-name-edit.component';
import { AdsManagerActionCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-action-cell/ads-manager-action-cell.component';
import { AdsManagerCompareCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-compare-cell/ads-manager-compare-cell.component';
import { AdsManagerDateCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-date-cell/ads-manager-date-cell.component';
import { AdsManagerEditCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-edit-cell/ads-manager-edit-cell.component';
import { AdsManagerImageCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-image-cell/ads-manager-image-cell.component';
import { AdsManagerLinkCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-link-cell/ads-manager-link-cell.component';
import { AdsManagerToggleCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-toggle-cell/ads-manager-toggle-cell.component';
import { CustomDateComponent } from './ag-grid-custom-cell-renderers/custom-date-component/custom-date-component.component';
import { CustomFieldCellRendererComponent } from './ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer.component';
import { BackOfficeNavigationComponent } from './back-office-navigation/back-office-navigation.component';
import { ButtonComponent } from './button-component/button.component';
import { ButtonRowComponent } from './button-row/button-row.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NgbToMomentToString } from './calendar/calendar.pipe';
import { CalendarService } from './calendar/calendar.service';
import { ClickOutsideDirective } from './click-outside-directive/click-outside.directive';
import { DropdownIconComponent } from './dropdown-icon/dropdown-icon.component';
import { DropdownSearchSelectComponent } from './dropdown-search-select/dropdown-search-select.component';
import { ExpansionPanelScComponent } from './expansion-panel-sc/expansion-panel-sc.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { ErrorMessageService } from './form-input/error-message.service';
import { GenericDropdownComponent } from './form-input/generic-dropdown/generic-dropdown.component';
import { InputCardNumberComponent } from './form-input/input-card-number/input-card-number.component';
import { InputNumberCardDirective } from './form-input/input-number-card.directive';
import { InputCvcComponent } from './form-input/input-cvc/input-cvc.component';
import { InputBillingDateComponent } from './form-input/input-date/input-billing-date.component';
import { InputMultipleEmailComponent } from './form-input/input-multiple-email/input-multiple-email.component';
import { InputNumberValueDirective } from './form-input/input-number-value.directive';
import { InputNumberComponent } from './form-input/input-number/input-number.component';
import { InputPasswordComponent } from './form-input/input-password/input-password.component';
import { InputTextComponent } from './form-input/input-text/input-text.component';
import { InputTextareaComponent } from './form-input/input-textarea/input-textarea.component';
import { LineInputTextComponent } from './form-input/line-input-text/line-input-text.component';
import { GenericTabsComponent } from './generic-tabs/generic-tabs.component';
import { HoverSelectDirective } from './hover-select-directive/hover-select.directive';
import { InfoBoxComponent } from './info/info-box/info-box.component';
import { InfoHoverComponent } from './info/info-hover/info-hover.component';
import { InlineSelectorComponent } from './inline-selector/inline-selector.component';
import { KnowledgeBasePopUpComponent } from './knowledge-base/knowledge-base-pop-up/knowledge-base-pop-up.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { AgGridDirective } from './master-table/directives/ag-grid.directive';
import { MasterTableComponent } from './master-table/master-table.component';
import { CheckPermissionsDirective } from './permisions/check-permissions.directive';
import { PermissionsService } from './permisions/permissions.service';
import { TablePermissionService } from './permisions/table-permissions/table-permission-service';
import { TablePermissionsComponent } from './permisions/table-permissions/table-permissions.component';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FormatTextPipe } from './pipes/format-text-pipe';
import { LimitLengthPipe } from './pipes/limit-length.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html';
import { SnakeCaseToHuman } from './pipes/snake-case-to-human.pipe';
import { ArraySortPipe } from './pipes/sort';
import { GenericPopupComponent } from './pop-ups/generic-popup/generic-popup.component';
import { RecommendationTemplateComponent } from './recommendation-template/recommendation-template.component';
import { AdAccountResolver } from './resolvers/ad-account.resolver';
import { ScrollOutsideDirective } from './scroll-outside-directive/scroll-outside.directive';
import { NameExpansionPanelComponent } from './smart-create-edit-components/step-one-components/name-expansion-pannel/name-expansion-panel.component';
import { DateCardComponent } from './smart-create-edit-components/step-two-components/date-card/date-card.component';
import { BudgetCardComponent } from './smart-create-edit-components/step-two-components/budget-card/budget-card.component';
import { PlacementsCardComponent } from './smart-create-edit-components/step-two-components/placements-card/placements-card.component';
import { AdFormatSelectionCardComponent } from './smart-create-edit-components/step-three-components/ad-format/ad-format-selection-card.component';
import { AdCreationCardComponent } from './smart-create-edit-components/step-three-components/ad-format/ad-creation-card/ad-creation-card.component';
import { PreviewCardComponent } from './smart-create-edit-components/step-three-components/ad-format/preview-card/preview-card.component';
import { PagesCardComponent } from './smart-create-edit-components/step-two-components/pages-card/pages-card.component';
import { ObjectiveCardComponent } from './smart-create-edit-components/step-one-components/objective-card/objective-card.component';
import { BidStrategyCardComponent } from './smart-create-edit-components/step-one-components/bid-strategy-card/bid-strategy-card.component';
import { SpecialAdCategoryCardComponent } from './smart-create-edit-components/step-one-components/special-ad-category-card/special-ad-category-card.component';
import { LiveSearchDropdownComponent } from './smart-create-edit-components/live-search-dropdown/live-search-dropdown.component';
import { AdCreationInputFormComponent } from './smart-create-edit-components/step-three-components/ad-format/ad-creation-input-form/ad-creation-input-form.component';
import { AssetPickerDialogComponent } from './smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-picker-dialog.component';
import { BuildUrlParameterDialogComponent } from './smart-create-edit-components/step-three-components/ad-format/build-url-parameter-dialog/build-url-parameter-dialog.component';
import { DropDirective } from './smart-create-edit-components/step-three-components/ad-format/drop.directive';
import { InstantFormDialogComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/instant-form-component/dialog-box/instant-form-dialog.component';
import { CustomeDisclaimerComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/custom-disclaimer/custome-disclaimer.component';
import { TrackingParameterComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/tracking-parameter/tracking-parameter.component';
import { InputWithCounterComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/input-with-counter/input-with-counter.component';
import { PixelCardComponent } from './smart-create-edit-components/step-three-components/pixel-card/pixel-card.component';
import { OptimizationAndDeliveryCardComponent } from './smart-create-edit-components/step-two-components/optimization-delivery/optimization-and-delivery-card.component';
import { AudienceNamePopUpComponent } from './smart-create-edit-components/step-two-components/targeting-card/pop-up/audience-name-pop-up.component';
import { TargetingCardComponent } from './smart-create-edit-components/step-two-components/targeting-card/targeting-card.component';
import { AdAccountEffects } from './state/effects/ad-account-effects.service';
import { GeneralSettingsEffects } from './state/effects/general-settings-effects.service';
import { InsightsEffects } from './state/effects/insights-effects.service';
import { sharedReducer } from './state/shared.reducer';
import { userReducer } from './state/user/user.reducer';
import { TableTabsItemsComponent } from './table-tabs-items/table-tabs-items.component';
import { WarningAnimationMessageComponent } from './warning-animation-message/warning-animation-message.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { FileUploadComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/file-upload/file-upload.component';
import { InstantChatDialogComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/automated-chat-component/dialog-box/instant-chat-dialog.component';
import { CustomQuestionComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/custom-question/custom-question.component';
import { InputWithEmojiComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/input-with-emoji/input-with-emoji.component';
import { MultipleAnswerQuestionComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/multiple-answer-question/multiple-answer-question.component';
import { ShortAnswerComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/short-answer/short-answer.component';
import { ConditionalQstnComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/conditional-qstn/conditional-qstn.component';
import { AppointmentRequestComponent } from './smart-create-edit-components/step-three-components/lead-generation-methods/mini-components/appointment-request/appointment-request.component';
import { RecommendationsNewTemplateComponent } from './recommendations-new-template/recommendations-new-template.component';
import { ChartComponent } from './elements/chart/chart.component';
import { ElementsMetricsComponent } from './elements/elements-metrics/elements-metrics.component';
import { ElementsRecommendationsComponent } from './elements/elements-recommendations/elements-recommendations.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { OptimizeService } from '../optimise/services/optimize.service';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { QuickNavigationScrollDirective } from './quick-navigation-scroll/quick-navigation-scroll.directive';
import { KnowledgeHeaderComponent } from './knowledge-base/component-pieces/knowledge-header/knowledge-header.component';
import { KnowledgeHomeComponent } from './knowledge-base/component-pieces/knowledge-home/knowledge-home.component';
import { KnowledgeSearchComponent } from './knowledge-base/component-pieces/knowledge-search/knowledge-search.component';
import { KnowledgeSearchResultComponent } from './knowledge-base/component-pieces/knowledge-search-result/knowledge-search-result.component';
import { SubmitTicketComponent } from './knowledge-base/component-pieces/submit-ticket/submit-ticket.component';
import { KnowledgeListComponent } from './knowledge-base/component-pieces/knowledge-list/knowledge-list.component';
import { KnowledgeViewComponent } from './knowledge-base/component-pieces/knowledge-view/knowledge-view.component';
import { KnowledgeSubComponent } from './knowledge-base/component-pieces/knowledge-sub/knowledge-sub.component';
import { KnowledgeFrameComponent } from './knowledge-base/component-pieces/knowledge-frame/knowledge-frame.component';
import { KnowledgebaseService } from './services/knowledgebase.service';
import { ViewTicketComponent } from './knowledge-base/component-pieces/view-ticket/view-ticket.component';
import { SelectAppCardComponent } from './smart-create-edit-components/step-two-components/select-app-card/select-app-card.component';
import { DexterHealthOverviewComponent } from './dexter-health/dexter-health-overview/dexter-health-overview.component';
import { HealthGenericCardComponent } from './dexter-health/health-generic-card/health-generic-card.component';
import { RecommendationsNewTemplateCardComponent } from './recommendations-new-template/recommendations-new-template-card.component';
import { FiledLibModule } from '@filed-com/filed-lib';
import { CardsModule } from '@filed-com/filed-lib';
import { RangeSliderModule } from '@filed-com/filed-lib';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { AddQuickSetOverlayComponent } from '../catalog/component-pieces/catalog-ag-grid-components/add-quick-set-overlay/add-quick-set-overlay.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { TargetNewComponent } from './smart-create-edit-components/step-two-components/targeting-card/target-new/target-new.component';
import { ReTargetingComponent } from './smart-create-edit-components/step-two-components/targeting-card/re-targeting/re-targeting.component';
import { AccountsService } from '../accounts/services/accounts.service';
import { AccountsMasterTableService } from '../accounts/services/accounts-master-table.service';
import { DefaultNoRowOverlayComponent } from './master-table/template/default-no-row-overlay/default-no-row-overlay.component';
import { CatalogSetSelectorComponent } from './smart-create-edit-components/step-two-components/catalog-set-selector/catalog-set-selector.component';
import { AppInstallCardComponent } from './smart-create-edit-components/step-three-components/app-install-card/app-install-card.component';
import { NewObjectiveCardComponent } from './smart-create-edit-components/step-one-components/new-objective-card/new-objective-card.component';
import { MatSortModule } from '@angular/material/sort';
import { MultiChannelAccountSelectionComponent } from './multi-channel-account-selection/multi-channel-account-selection.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { CustomDrpdownOp1Component } from './input-styles/custom-drpdown-op1/custom-drpdown-op1.component';
import { InputDisabledComponent } from './input-disabled/input-disabled.component';
import { CustomDrpdownOp2Component } from './input-styles/custom-drpdown-op2/custom-drpdown-op2.component';
import { FormFieldModule, NumericTextBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SelectionCardComponent } from './selection-card/selection-card.component';
@NgModule({
	imports: [
		MaterialModule,
		CommonModule,
		StoreModule.forFeature('shared', sharedReducer),
		StoreModule.forFeature('user', userReducer),
		EffectsModule.forFeature([AdAccountEffects, GeneralSettingsEffects, InsightsEffects]),
		AgGridModule.withComponents([AddQuickSetOverlayComponent, DefaultNoRowOverlayComponent]),
		ToggleModule,
		MatButtonToggleModule,
		NgSelectModule,
		TranslateModule,
		NgbDatepickerModule,
		HourSelectorModule,
		GridsterModule,
		DragDropModule,
		MatMenuModule,
		NgxEchartsModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		NgxMatSelectSearchModule,
		MatDialogModule,
		MatSnackBarModule,
		ScrollingModule,
		InputsModule,
		FormFieldModule,
		NumericTextBoxModule,
		TextBoxModule,
		RouterModule,
		Ng5SliderModule,
		ButtonsModule,
		TreeViewsModule,
		SlickCarouselModule,
		TextBoxesModule,
		TooltipsModule,
		DropdownsModule,
		LoadersModule,
		GraphsModule,
		LoadersModule,
		CheckboxesModule,
		FontsModule,
		TextAreasModule,
		GraphsModule,
		GoogleChartsModule,
		FiledLibModule,
		FontsModule,
		CardsModule,
		NumericTextboxesModule,
		RangeSliderModule,
		MatSortModule
	],
	declarations: [
		DefaultNoRowOverlayComponent,
		CheckPermissionsDirective,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		ButtonComponent,
		InfoBoxComponent,
		WelcomePageComponent,
		ShareDashboardAsLinkComponent,
		AccountDropdownComponent,
		NoResourceComponent,
		CalendarComponent,
		NgbToMomentToString,
		AddButtonComponent,
		DropDownButtonComponent,
		SpinnerFiledComponent,
		GenericSearchComponent,
		SpinnerComponent,
		ChartsComponent,
		ManageAdAccountPermissionsComponent,
		SettingsComponent,
		FilterTabListComponent,
		WidgetLoaderComponent,
		AssetsGalleryComponent,
		UploadComponent,
		GalleryMatTabComponent,
		TreeChecklistComponent,
		FilterTextPipe,
		ContainsPipe,
		ConfirmationDialogComponent,
		InformationDialogComponent,
		AdBlockerComponent,
		RoundedImageContainerComponent,
		FormatTextPipe,
		WarningMessageNoDataComponent,
		GenericChartsComponent,
		CamelCaseToTextPipe,
		ToastNotificationComponent,
		InputTextComponent,
		ArraySortPipe,
		ButtonRowComponent,
		InfoHoverComponent,
		InlineSelectorComponent,
		InputNumberComponent,
		InputNumberValueDirective,
		DashboardGridComponent,
		LineInputTextComponent,
		InputTextareaComponent,
		InputMultipleEmailComponent,
		InputPasswordComponent,
		DropdownSearchSelectComponent,
		RecommendationTemplateComponent,
		DropdownIconComponent,
		InputCardNumberComponent,
		InputNumberCardDirective,
		InputCvcComponent,
		InputBillingDateComponent,
		DragDropDirective,
		DragAndDropDirective,
		ExpansionPanelComponent,
		ExpansionPanelScComponent,
		CardBoxComponent,
		HoverSelectDirective,
		ClickOutsideDirective,
		ScrollOutsideDirective,
		FilterArrayPipe,
		WarningAnimationMessageComponent,
		AdsManagerActionCellComponent,
		AdsManagerToggleCellComponent,
		AdsManagerNameEditComponent,
		AdsManagerLinkCellComponent,
		AdsManagerCompareCellComponent,
		AdsManagerImageCellComponent,
		GenericPopupComponent,
		TablePermissionsComponent,
		BackOfficeNavigationComponent,
		TableTabsItemsComponent,
		TargetingCardComponent,
		NameExpansionPanelComponent,
		DateCardComponent,
		BudgetCardComponent,
		PlacementsCardComponent,
		AdFormatSelectionCardComponent,
		AdCreationCardComponent,
		PreviewCardComponent,
		PagesCardComponent,
		ObjectiveCardComponent,
		BidStrategyCardComponent,
		SpecialAdCategoryCardComponent,
		PixelCardComponent,
		LiveSearchDropdownComponent,
		AdCreationInputFormComponent,
		AssetPickerDialogComponent,
		BuildUrlParameterDialogComponent,
		DropDirective,
		AdsManagerEditCellComponent,
		AdsManagerDateCellComponent,
		CustomDateComponent,
		MasterTableComponent,
		AgGridDirective,
		LimitLengthPipe,
		KnowledgeBaseComponent,
		KnowledgeBasePopUpComponent,
		KnowledgeHeaderComponent,
		KnowledgeHomeComponent,
		KnowledgeSearchComponent,
		KnowledgeSearchResultComponent,
		SubmitTicketComponent,
		KnowledgeListComponent,
		KnowledgeViewComponent,
		KnowledgeSubComponent,
		KnowledgeFrameComponent,
		ViewTicketComponent,
		GenericDropdownComponent,
		CustomFieldCellRendererComponent,
		AudienceNamePopUpComponent,
		GenericTabsComponent,
		OptimizationAndDeliveryCardComponent,
		RecommendationsNewTemplateComponent,
		RecommendationsNewTemplateCardComponent,
		ChartComponent,
		ElementsMetricsComponent,
		ElementsRecommendationsComponent,
		QuickNavigationScrollDirective,
		InputWithCounterComponent,
		CustomeDisclaimerComponent,
		InstantFormDialogComponent,
		TrackingParameterComponent,
		FileUploadComponent,
		InstantChatDialogComponent,
		CustomQuestionComponent,
		InputWithEmojiComponent,
		MultipleAnswerQuestionComponent,
		ShortAnswerComponent,
		ConditionalQstnComponent,
		AppointmentRequestComponent,
		SelectAppCardComponent,
		DexterHealthOverviewComponent,
		HealthGenericCardComponent,
		QuickNavigationScrollDirective,
		TargetNewComponent,
		ReTargetingComponent,
		QuickNavigationScrollDirective,
		SafeURLPipe,
		CatalogSetSelectorComponent,
		AppInstallCardComponent,
		NewObjectiveCardComponent,
		MultiChannelAccountSelectionComponent,
		InputFieldComponent,
		CustomDrpdownOp1Component,
		InputDisabledComponent,
		CustomDrpdownOp2Component,
		SelectionCardComponent
	],
	exports: [
		MatInputModule,
		MatNativeDateModule,
		MatRippleModule,
		MatDatepickerModule,
		QuickNavigationScrollDirective,
		CheckPermissionsDirective,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		AccountDropdownComponent,
		SpinnerFiledComponent,
		NoResourceComponent,
		TranslateModule,
		SpinnerComponent,
		NgbDatepickerModule,
		AddButtonComponent,
		DropDownButtonComponent,
		FilterTabListComponent,
		ManageAdAccountPermissionsComponent,
		GenericSearchComponent,
		WelcomePageComponent,
		MatButtonToggleModule,
		MatMenuModule,
		SettingsComponent,
		WidgetLoaderComponent,
		TreeChecklistComponent,
		AgGridModule,
		NgxEchartsModule,
		CalendarComponent,
		FilterTextPipe,
		ContainsPipe,
		ChartsComponent,
		CamelCaseToTextPipe,
		RoundedImageContainerComponent,
		FormatTextPipe,
		WarningMessageNoDataComponent,
		MaterialModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		NgxMatSelectSearchModule,
		MatDialogModule,
		MatSnackBarModule,
		ScrollingModule,
		ToastNotificationComponent,
		GenericChartsComponent,
		InputTextComponent,
		ArraySortPipe,
		GridsterModule,
		ButtonRowComponent,
		InfoBoxComponent,
		InfoHoverComponent,
		ButtonComponent,
		InlineSelectorComponent,
		DashboardGridComponent,
		InputNumberComponent,
		LineInputTextComponent,
		InputTextareaComponent,
		ShareDashboardAsLinkComponent,
		InputMultipleEmailComponent,
		InputPasswordComponent,
		RecommendationTemplateComponent,
		DropdownSearchSelectComponent,
		NgSelectModule,
		DropdownIconComponent,
		InputCardNumberComponent,
		InputCvcComponent,
		InputBillingDateComponent,
		DragDropDirective,
		DragAndDropDirective,
		ExpansionPanelComponent,
		CardBoxComponent,
		ClickOutsideDirective,
		FilterArrayPipe,
		HoverSelectDirective,
		WarningAnimationMessageComponent,
		MasterTableComponent,
		TargetingCardComponent,
		NameExpansionPanelComponent,
		DateCardComponent,
		BudgetCardComponent,
		PlacementsCardComponent,
		AdFormatSelectionCardComponent,
		AdCreationCardComponent,
		PreviewCardComponent,
		PagesCardComponent,
		ObjectiveCardComponent,
		BidStrategyCardComponent,
		SpecialAdCategoryCardComponent,
		PixelCardComponent,
		LiveSearchDropdownComponent,
		AdCreationInputFormComponent,
		AssetPickerDialogComponent,
		BuildUrlParameterDialogComponent,
		DropDirective,
		AudienceNamePopUpComponent,
		TableTabsItemsComponent,
		TablePermissionsComponent,
		BackOfficeNavigationComponent,
		LimitLengthPipe,
		GenericPopupComponent,
		KnowledgeBaseComponent,
		KnowledgeHeaderComponent,
		KnowledgeHomeComponent,
		KnowledgeSearchComponent,
		KnowledgeSearchResultComponent,
		KnowledgeListComponent,
		KnowledgeViewComponent,
		KnowledgeSubComponent,
		KnowledgeFrameComponent,
		ViewTicketComponent,
		GenericDropdownComponent,
		GenericTabsComponent,
		OptimizationAndDeliveryCardComponent,
		RecommendationsNewTemplateComponent,
		RecommendationsNewTemplateCardComponent,
		ExpansionPanelScComponent,
		ChartComponent,
		ElementsMetricsComponent,
		ElementsRecommendationsComponent,
		DexterHealthOverviewComponent,
		HealthGenericCardComponent,
		TooltipsModule,
		DropdownsModule,
		InputWithCounterComponent,
		CustomeDisclaimerComponent,
		TrackingParameterComponent,
		FileUploadComponent,
		InstantChatDialogComponent,
		CustomQuestionComponent,
		InputWithEmojiComponent,
		MultipleAnswerQuestionComponent,
		ShortAnswerComponent,
		ConditionalQstnComponent,
		AppointmentRequestComponent,
		LoadersModule,
		LoadersModule,
		SelectAppCardComponent,
		LoadersModule,
		FiledLibModule,
		FontsModule,
		CardsModule,
		NumericTextboxesModule,
		RangeSliderModule,
		TooltipsModule,
		DropdownsModule,
		ButtonsModule,
		TextBoxesModule,
		SafeURLPipe,
		ReTargetingComponent,
		CatalogSetSelectorComponent,
		AppInstallCardComponent,
		NewObjectiveCardComponent,
		MatSortModule,
		MultiChannelAccountSelectionComponent,
		InputFieldComponent,
		CustomDrpdownOp1Component,
		InputDisabledComponent,
		CustomDrpdownOp2Component,
		SelectionCardComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [
		TablePermissionService,
		PermissionsService,
		ErrorMessageService,
		TitleCasePipe,
		ReportingResolver,
		DashboardTemplateResolver,
		CalendarService,
		BusinessOwnerService,
		UserServiceApi,
		ConfirmationDialogComponent,
		GoogleInsightsMetadataService,
		AdAccountResolver,
		CheckPermissionsDirective,
		OptimizeService,
		KnowledgebaseService,
		AccountsService,
		AccountsMasterTableService,
		InterceptorsProvider
	]
})
export class SharedModule {}
