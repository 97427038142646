import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { StorageKey } from '../_models/local-storage-key';
import { BehaviorSubject, Observable, of, pipe, Subject } from 'rxjs';
import { TokenService } from './token.service';
import { UserDetails } from '../_models/identity-models/identity-detail';
import { UserStateEnum } from './user/user-state.enum';
import { select, Store } from '@ngrx/store';
import { LogOut, RestoreAuthentication } from '../authentication/state/authentication.action';
import { getAccountState, getUserDetails, hasBackOfficePermission, hasClientPermission, UserState } from '../shared/state/user/user.reducer';
import { SetUserDetails } from '../shared/state/user/user.actions';
import { subscribeOn, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthenticationState } from '../authentication/state/authentication.reducer';
import { ResetAppState } from '../state/app.actions';
import moment from 'moment';
import { PermissionsService } from '../shared/permisions/permissions.service';
import { UserDetailsInterface } from '../_models/identity-models/user-details.interface';
import { GoogleService } from './google/google.service';
import { pick } from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	public logoutSubject: Subject<void> = new Subject();
	public setRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	public allowedMegaRoutes$: BehaviorSubject<string[]> = new BehaviorSubject([]);
	public triggerNavRoute$: BehaviorSubject<string> = new BehaviorSubject('audience');
	public bigCommerceAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setSubNavIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
	public expandMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setExternalRoute$: BehaviorSubject<{ route: string; type: string }> = new BehaviorSubject(null);

	private userDetails: UserDetails;
	private googleIntegrated: boolean = false;
	constructor(
		private router: Router,
		private tokenService: TokenService,
		private permissionsService: PermissionsService,
		private userStore: Store<UserState>,
		private googleService: GoogleService,
		private authStore: Store<AuthenticationState>
	) {}

	public isLoggedIn() {
		const tokenExists: boolean = !!localStorage.getItem(StorageKey.token);
		if (tokenExists) {
			const decodedJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
			const currentTime = moment().format();
			const timeFromJwt = moment(decodedJwt.exp * 1000).format();
			const decodedJwtIsValid: boolean = !moment(currentTime).isAfter(timeFromJwt);
			this.initUserDetails();

			return tokenExists && decodedJwtIsValid;
		}
	}
	public initUserDetails(): void {
		if (!this.userDetails) {
			const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
			this.userDetails = this.getUserDetailsFromJwt(decodedToken);
		}
	}
	public hasCreditCard(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState !== UserStateEnum.NoCreditCard && accountState !== UserStateEnum.FreeTrialExpiredNoCreditCard))
		);
	}

	public hasBusinessOwner(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState !== UserStateEnum.NoBusinessOwner))
		);
	}

	public hasClientEmployee(): Observable<boolean> {
		return this.userStore.pipe(
			select(hasClientPermission),
			take(1),
			switchMap(isClientEmployee => of(isClientEmployee))
		);
	}

	public isAdmin(): Observable<boolean> {
		return this.userStore.pipe(select(hasBackOfficePermission), take(1));
	}

	public isFreemiumExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreemiumExpiredNoCreditCard))
		);
	}

	public isFreeTrialExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreeTrialExpiredNoCreditCard))
		);
	}

	public logout(params?: NavigationExtras): void {
		// TODO hack because we dont have refresh token
		const temporaryCredentials = localStorage.getItem('details');
		localStorage.clear();
		if (temporaryCredentials) {
			localStorage.setItem('details', temporaryCredentials);
		}

		this.logoutSubject.next();

		this.userStore.dispatch(new ResetAppState());
		this.authStore.dispatch(new LogOut());

		params ? this.router.navigate(['/authentication'], params) : this.router.navigate(['/authentication']);
	}

	public initUserRolesLogic(): void {
		this.tokenService.token = localStorage.getItem(StorageKey.token);
		if (!this.tokenService.token) {
			return;
		}

		try {
			const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
			this.userDetails = this.getUserDetailsFromJwt(decodedToken);
			this.userStore.dispatch(new SetUserDetails(this.userDetails));
			this.authStore.dispatch(new RestoreAuthentication(this.tokenService.token));
		} catch (e) {
			this.logout();
		}
	}
	public checkUserData(): void {
		this.googleService
			.checkGoogleStatus()
			.pipe(take(1))
			.subscribe(
				res => {
					if (res.filed_business_owner_id) {
						localStorage.setItem(StorageKey.installedPlatorm, JSON.stringify(['Google']));
					} else {
						localStorage.setItem(StorageKey.installedPlatorm, JSON.stringify([]));
					}
				},
				err => {
					localStorage.setItem(StorageKey.installedPlatorm, JSON.stringify([]));
				}
			);
	}
	public getUserDetailsFromJwt(decodedJwt: UserDetailsInterface): UserDetails {
		let googleIntegratedStatus;
		let itemString: string = localStorage.getItem(StorageKey.installedPlatorm);
		if (!!itemString && JSON.parse(itemString)?.includes('Google')) {
			googleIntegratedStatus = decodedJwt.user_filed_id;
		}

		const codedPermissions: string[] = decodedJwt.permissions_filed.split('|');
		const decodedPermissions = codedPermissions
			.map(permission => this.permissionsService.decodePermission(permission))
			.filter(permission => permission.permissions.length);
		return {
			FiledId: parseInt(decodedJwt.user_filed_id),
			OriginalFiledId: parseInt(decodedJwt.original_filed_id),
			AccountState: parseInt(decodedJwt.user_account_state),
			FacebookBusinessOwnerId: decodedJwt.user_facebook_businessowner_id,
			GoogleBusinessOwnerId: googleIntegratedStatus,
			IsFrontOfficeUser: decodedJwt.user_is_frontoffice_user === 'True',
			RejectedReason: parseInt(decodedJwt.user_rejected_reason),
			IsImpersonated: false,
			Permissions: decodedPermissions
		};
	}
}
