import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { LoadUsers } from '../../../user-management/state/user-management.actions';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { getUsers, UserManagementState } from '../../../user-management/state/user-management.reducer';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { User } from '../../../_models/user-models/user';
import { getFacebookBusinessOwnerId } from '../../../shared/state/user/user.reducer';
import { SharedState } from '../../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';

@Injectable()
export class FiledUsersResolver implements Resolve<User[]> {
	private businessOwnerFacebookId: string;

	constructor(
		private router: Router,
		private store: Store<UserManagementState>,
		private toastNotification: ToastNotificationService,
		private sharedStore: Store<SharedState>
	) {
		this.fetchStore();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User[]> {
		this.store.dispatch(new LoadUsers(this.businessOwnerFacebookId));
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		return this.store.pipe(
			select(getUsers),
			filter(users => users != null),
			take(1),
			tap(users => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				if (users) {
					return EMPTY;
				}
			}),
			catchError(() => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
				this.router.navigate(['/auth-landing']);
				return EMPTY;
			})
		);
	}

	private fetchStore(): void {
		this.store.pipe(select(getFacebookBusinessOwnerId)).subscribe(businessOwnerFacebookId => {
			this.businessOwnerFacebookId = businessOwnerFacebookId;
		});
	}
}
