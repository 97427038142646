import { Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { CardDetailsInterface, TarrifPlansInterface } from '../../sign-up/subscribe.interface';
import { getUserRegistrationDetail } from '../../state/authentication.reducer';
declare var bluesnap: any;

@Component({
	selector: 'app-payment-page',
	templateUrl: './payment-page.component.html',
	styleUrls: ['./payment-page.component.scss']
})
export class PaymentPageComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public planDetails: any;
	public cardDetails: FormGroup;
	public expirationDate: FormControl;
	public cardNumber: FormControl;

	public submitting: boolean;

	public currentRoute: string;

	public countries: Country[];
	public countryData: any[];
	public formControlsRequests: any = {};
	public isNewCard: boolean = true;
	public hasUserAgreed: boolean = false;
	public paymentModeSelected: boolean = false;
	public paymentWallType: string;
	public PfToken: string;

	@ViewChild('ccNumber') ccNumberField!: ElementRef;

	protected sharedStore: Store<SharedState>;
	constructor(
		private injector: Injector,
		private formBuilder: FormBuilder,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private router: Router,
		public backOfficeService: BackOfficeService
	) {
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
	}

	ngOnInit(): void {
		this.loadScript();
		this.setDropdownCountries();
		this.createForm();
		this.getTarrifDetails();
		this.onFormNameChange();
	}

	private loadScript() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = BaseApiUrl.BlueSnap;
		const head = document.getElementsByTagName('head')[0];
		if (head !== null && head !== undefined) {
			document.head.appendChild(script);
		}
	}
	public getPfToken(): void {
		this.billingService.getPfToken().subscribe(res => {
			this.PfToken = res;
			if (this.paymentWallType == 'BlueSnap') {
				this.onReady();
			}
		});
	}

	public selectMode(mode: string): void {
		this.getPfToken();
		this.paymentModeSelected = true;
		this.paymentWallType = mode;
		this.setValidators();
	}

	public setValidators(): void {
		if (this.paymentWallType == 'BlueSnap') {
			this.cardDetails.get('cardName').setValidators(Validators.required);
			this.cardDetails.get('cardNumber').setValidators(Validators.required);
			this.cardDetails.get('securityCode').setValidators(Validators.required);
			this.cardDetails.get('expirationDate').setValidators(Validators.required);
		} else {
			this.cardDetails.get('cardName').clearValidators();
			this.cardDetails.get('cardNumber').clearValidators();
			this.cardDetails.get('securityCode').clearValidators();
			this.cardDetails.get('expirationDate').clearValidators();
		}
	}

	public deselectMode(): void {
		this.paymentModeSelected = false;
		this.paymentWallType = null;
		this.onMarkDefault(false);
		this.setValidators();
		this.cardDetails.reset();
	}

	public onReady(): void {
		var cardUrl = {
			AMEX: 'https://files.readme.io/97e7acc-Amex.png',
			DINERS: 'https://files.readme.io/8c73810-Diners_Club.png',
			DISCOVER: 'https://files.readme.io/caea86d-Discover.png',
			JCB: 'https://files.readme.io/e076aed-JCB.png',
			MASTERCARD: 'https://files.readme.io/5b7b3de-Mastercard.png',
			VISA: 'https://files.readme.io/9018c4f-Visa.png'
		};
		var bsObj = {
			//insert your Hosted Payment Fields token
			token: this.PfToken,
			onFieldEventHandler: {
				onFocus: function (tagId) {
					// Handle focus
					changeImpactedElement(tagId, 'hosted-field-valid hosted-field-invalid', 'hosted-field-focus');
				},
				onBlur: function (tagId) {
					// Handle blur
					changeImpactedElement(tagId, 'hosted-field-focus', '');
				},
				onError: function (tagId, errorCode, errorDescription) {
					// Handle a change in validation
					changeImpactedElement(tagId, 'hosted-field-valid hosted-field-focus', 'hosted-field-invalid');
					var error: string;
					switch (tagId) {
						case 'ccn':
							error = 'Card Invalid';
							onFormError(tagId);
							break;
						case 'exp':
							error = 'Expiry Date Invalid';
							onFormError(tagId);
							break;
						case 'cvv':
							error = 'CVV Invalid';
							onFormError(tagId);
							break;
					}
					$('#' + tagId + '-help')
						.removeClass('helper-text-green')
						.text(error);
				},
				onType: function (tagId, cardType, cardData) {
					// get card type from cardType and display card image
					$('#card-logo > img').attr('src', cardUrl[cardType]);
				},
				onValid: function (tagId) {
					// Handle a change in validation
					changeImpactedElement(tagId, 'hosted-field-focus hosted-field-invalid', 'hosted-field-valid');
					$('#' + tagId + '-help').text('');
					onFormValid(tagId);
				}
			},
			//styling is optional
			style: {
				// Styling all inputs
				input: {
					'font-size': '14px',
					'font-family': 'Helvetica Neue,Helvetica,Arial,sans-serif',
					'line-height': '1.42857143',
					color: '#555'
				},

				// Styling a specific field
				/*"#ccn": {
								    
								},*/

				// Styling Hosted Payment Field input state
				':focus': {
					color: '#555'
				}
			},
			ccnPlaceHolder: 'Card Number*',
			cvvPlaceHolder: 'CVV*',
			expPlaceHolder: 'MM/YY*'
		};
		function changeImpactedElement(tagId, removeClass, addClass) {
			removeClass = removeClass || '';
			addClass = addClass || '';
			$('[data-bluesnap=' + tagId + ']')
				.removeClass(removeClass)
				.addClass(addClass);
		}
		const onFormValid = (tagId): void => {
			switch (tagId) {
				case 'ccn':
					this.cardDetails.get('cardNumber').patchValue(tagId);
					break;
				case 'exp':
					this.cardDetails.get('expirationDate').patchValue(tagId);
					break;
				case 'cvv':
					this.cardDetails.get('securityCode').patchValue(tagId);
					break;
			}
		};
		const onFormError = (tagId): void => {
			switch (tagId) {
				case 'ccn':
					this.cardDetails.get('cardNumber').patchValue(null);
					break;
				case 'exp':
					this.cardDetails.get('expirationDate').patchValue(null);
					break;
				case 'cvv':
					this.cardDetails.get('securityCode').patchValue(null);
					break;
			}
		};
		bluesnap.hostedPaymentFieldsCreate(bsObj);
	}

	public paywithPftoken(): void {
		bluesnap.hostedPaymentFieldsSubmitData(callback => {
			if (null != callback.error) {
				this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
			} else {
				this.finalSubmit();
			}
		});
	}

	public noWhitespaceValidator(control: FormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return { whitespace: true };
	}

	public createForm(): void {
		this.cardDetails = this.formBuilder.group({
			cardName: new FormControl(''),
			cardNumber: new FormControl(''),
			securityCode: new FormControl(''),
			expirationDate: new FormControl(''),
			firstAddress: new FormControl('', Validators.required),
			secondAddress: new FormControl(''),
			city: new FormControl('', Validators.required),
			zipCode: new FormControl('', Validators.required),
			country: new FormControl('', Validators.required),
			agreedToPrivacy: new FormControl(false, Validators.required)
		});

		this.expirationDate = new FormControl('', [
			Validators.required,
			Validators.pattern('^(?=.*[0-9])[/0-9]+$'),
			Validators.minLength(6),
			Validators.maxLength(7)
		]);
	}

	private setDropdownCountries(): void {
		this.backOfficeService
			.getAllCountriesList()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.countries = result;
				this.countryData = this.countries.map((value, index) => {
					return {
						value: value,
						label: value.name,
						id: index
					};
				});
			});
	}

	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.cardDetails.controls['country'].setValue(selected);
		}
	}

	public formatExpiryDate(value: any): void {
		value = this.expirationDate.value;
		if (!value.includes('/')) {
			value = value
				.replace(/\W/gi, '')
				.replace(/(.{2})/g, '$1/')
				.replace(/\/+$/, '');
			this.expirationDate.setValue(value);
		}

		const expires = value.split('/');
		this.cardDetails.controls['expirationMonth'].setValue(expires[0]);
		let yearValue = expires[1] || expires[0];
		if (expires[1] && expires[1].length == 3) {
			this.expirationDate.setErrors({ incorrect: true });
		}
		this.cardDetails.controls['expirationYear'].setValue(yearValue);
	}

	public creditCardNumberSpacing(value: any): void {
		value = this.cardDetails.get('cardNumber').value;
		value = value
			.replace(/\W/gi, '')
			.replace(/(.{4})/g, '$1 ')
			.replace(/\s+$/, '');
		this.cardDetails.get('cardNumber').setValue(value);
	}
	public onFormNameChange(): void {
		this.cardDetails.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {});
	}

	public getTarrifDetails(): void {
		this.submitting = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getUserTarrifPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				if (details) {
					details.amount = this.roundFigure(details.amount);
					var dates = details.nextInvoiceDate.split('T')[0].split('-');
					details.nextInvoiceDate = `${dates[2]}.${dates[1]}.${dates[0]}`;
				}
				this.planDetails = details;
			});
		this.submitting = false;
		this.sharedStore.dispatch(new HideGlobalSpinner());
	}

	get getPlanAmount(): string {
		let cur = this.planDetails?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.planDetails?.currency || 'USD'
		}).format(this.planDetails?.amount || 0);
		return cur + ' ' + amount;
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	public continueSignup(): void {
		if (this.cardDetails.invalid || !this.hasUserAgreed) {
			this.toastService.sendErrorToast('Please fill the required fields.');
			this.cardDetails.markAllAsTouched();
		} else {
			if (this.paymentWallType == 'BlueSnap') {
				this.paywithPftoken();
			} else if (this.paymentWallType == 'PayPal') {
				this.finalSubmit();
			}
		}
	}

	public finalSubmit(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.submitting = true;
		var country = this.cardDetails.get('country').value;
		const card_detail: CardDetailsInterface = {
			address1: this.cardDetails.get('firstAddress').value,
			address2: this.cardDetails.get('secondAddress').value,
			city: this.cardDetails.get('city').value,
			zipCode: this.cardDetails.get('zipCode').value,
			country: country.value,
			paymentWallType: this.paymentWallType,
			pfToken: this.PfToken
		};
		this.billingService
			.postCardDetails(card_detail)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detailResponse => {
					if (detailResponse.isSuccess == true) {
						switch (this.paymentWallType) {
							case 'BlueSnap':
								this.router.navigate(['/authentication/payment-confirmation']);
								break;
							case 'PayPal':
								window.location.href = detailResponse.paypalTransaction.paypalUrl;
								break;
						}
					} else {
						this.getPfToken();
						this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
						this.sharedStore.dispatch(new HideGlobalSpinner());
						this.submitting = false;
					}
				},
				({ error }) => {
					this.getPfToken();
					this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.submitting = false;
				},
				() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onMarkDefault(isChecked: boolean): void {
		this.hasUserAgreed = isChecked;
		this.cardDetails.controls['agreedToPrivacy'].setValue(isChecked);
	}
	public openPrivacyPolicy(): void {
		window.location.href = 'https://www.filed.com/privacy-policy-of-filed/';
	}
}
