import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthenticationState, getAccountConnectionState, getIsFirstTimeCustomer } from './authentication.reducer';
import { AccountConnectionState, AuthenticationActionType, LogIn, LogInFailure, LogInSuccess, ShowOtpInput } from './authentication.action';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { of } from 'rxjs';

import { TokenService } from '../../_services/token.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { UserState } from '../../shared/state/user/user.reducer';
import { SetUserDetails } from '../../shared/state/user/user.actions';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { AppStateSlices } from '../../state/app.state';
import { ErrorsLoggingService } from '../../_services/errors-logging/errors-logging.service';
import { AuthenticationEffectsEnum } from './effects.enum';
import { UserRejectedReason } from '../../_services/user/user-rejected-reason.enum';
import { MiscellaneousPermissions } from '../../shared/permisions/enums/miscellaneous-permissions';
import { Modules } from '../../shared/permisions/enums/modules';
import { GenericPopupComponent } from 'src/app/shared/pop-ups/generic-popup/generic-popup.component';
import { PopUpInterface } from 'src/app/shared/models/generic-popup-interface';
import { MatDialog } from '@angular/material/dialog';
import { GoogleService } from 'src/app/_services/google/google.service';
import { SMIState } from 'src/app/social-media-influencer/state/smi.reducer';
import { SaveNextPage } from 'src/app/social-media-influencer/state/smi.actions';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';

@Injectable()
export class AuthenticationEffects {
	private launchHandler: NodeJS.Timeout;
	public constructor(
		private router: Router,
		private actions$: Actions,
		private userServiceApi: UserServiceApi,
		private googleService: GoogleService,
		private tokenService: TokenService,
		private authenticationService: AuthenticationService,
		private toastNotificationService: ToastNotificationService,
		private authStore: Store<AuthenticationState>,
		private userStore: Store<UserState>,
		private errorsLoggingService: ErrorsLoggingService,
		private matDialog: MatDialog,
		private smiStore: Store<SMIState>,
		private backOfficeService: BackOfficeService
	) {}

	logIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LogIn>(AuthenticationActionType.LogIn),
			map(action => action.payload),
			mergeMap(payload =>
				payload
					? this.userServiceApi.authenticate(payload.email, payload.password, payload.code).pipe(
							map(result => new LogInSuccess(result)),
							catchError(error => {
								this.errorsLoggingService.logEffectError(AppStateSlices.Authentication, AuthenticationEffectsEnum.logIn, [payload], error);
								return of(new LogInFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
							})

							//switchMap(result => {
							//	if (result === 'True') {
							//		this.authStore.dispatch(new ShowOtpInput(true));
							//		return of("");
							//	} else if (result !== 'True' && result !== 'False') {
							//		return of(new LogInSuccess(result));
							//	} else {
							//		return of(new LogInFailure('Unable to send OTP'));
							//	}
							//}),
							//catchError(error => {
							//	this.errorsLoggingService.logEffectError(AppStateSlices.Authentication, AuthenticationEffectsEnum.logIn, [payload], error);
							//	return of(new LogInFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
							//})
					  )
					: this.userServiceApi.refreshUserToken().pipe(
							map(result => new LogInSuccess(result)),
							catchError(error => {
								this.errorsLoggingService.logEffectError(AppStateSlices.Authentication, AuthenticationEffectsEnum.logIn, [payload], error);
								return of(new LogInFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
							})
					  )
			)
		)
	);

	logInSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<LogInSuccess>(AuthenticationActionType.LogInSuccess),
				tap(payload => {
					if (payload.payload === 'True') {
						this.authStore.dispatch(new ShowOtpInput(true));
						return;
					}

					if (payload.payload === 'False') {
						this.authenticationService.logout();
						this.toastNotificationService.sendErrorToast(
							'There was an issue with sending out a message. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
						);
						return;
					}
					const token = payload.payload;
					localStorage.setItem(StorageKey.token, token);
					const decodedJwtIo = this.tokenService.decodeToken(token);
					const userDetails = this.authenticationService.getUserDetailsFromJwt(decodedJwtIo);
					if (userDetails?.RejectedReason === UserRejectedReason.FrontOfficeUserWithZeroAdAccounts) {
						this.authenticationService.logout();
						this.toastNotificationService.sendErrorToast(
							'There was an issue with syncing your data. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
						);
						return;
					}
					this.smiStore.dispatch(new SaveNextPage(-1));
					localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(decodedJwtIo));
					this.userStore.dispatch(new SetUserDetails(userDetails));
					switch (userDetails.AccountState) {
						case UserStateEnum.Active:
							localStorage.removeItem('details');
							let hasBackOfficePermission = false;
							let backOfficeDefaultUrl = 'back-office';
							userDetails.Permissions.filter(module => module.module === Modules.Miscellaneous).forEach(module => {
								module.permissions.forEach(permission => {
									if (
										permission === MiscellaneousPermissions.IsAdmin ||
										permission === MiscellaneousPermissions.IsAccountManagerAdmin ||
										permission === MiscellaneousPermissions.IsSales ||
										permission === MiscellaneousPermissions.IsSalesAdmin ||
										permission === MiscellaneousPermissions.IsAccountManager
									) {
										if (permission === MiscellaneousPermissions.IsSales) {
											backOfficeDefaultUrl = 'back-office/role';
										}
										hasBackOfficePermission = true;
									}
								});
							});

							const bigCommerceReload = this.authenticationService.bigCommerceAction$.value;

							if (hasBackOfficePermission) {
								this.router.navigate([backOfficeDefaultUrl]);
							} else if (bigCommerceReload) {
								this.bigCommerceReloadAction();
								this.authenticationService.bigCommerceAction$.next(null);
							} else {
								this.authStore.pipe(select(getIsFirstTimeCustomer), take(1)).subscribe(isFirstTimeCustomer => {
									// if (!isFirstTimeCustomer) {
									// 	this.router.navigate(['auth-landing']);
									// } else {
									// 	// TO BE UPDATED
									// 	// this.router.navigate([userDetails.FacebookBusinessOwnerId ? 'welcome-facebook' : 'welcome-google']);
									// 	this.router.navigate(['auth-landing']);
									// }
									const checkSignUpGetStarted = this.backOfficeService.signUpGetStartedActive$.value;
									if (checkSignUpGetStarted !== 'active') {
										if (!isFirstTimeCustomer) {
											this.router.navigate(['auth-landing']);
										} else {
											// TO BE UPDATED
											// this.router.navigate([userDetails.FacebookBusinessOwnerId ? 'welcome-facebook' : 'welcome-google']);
											this.router.navigate(['auth-landing']);
										}
									} else if (checkSignUpGetStarted === 'active') {
										this.backOfficeService.signUpGetStartedActive$.next('running');
									}
								});
							}
							break;
						case UserStateEnum.NoBusinessOwner:
							this.router.navigate(['auth-landing']);
							break;
						case UserStateEnum.NoCreditCard:
							this.router.navigate(['authentication/payment']);
							break;
						case UserStateEnum.FreemiumExpiredNoCreditCard:
							this.router.navigate(['authentication/trial-end']);
							break;
						case UserStateEnum.FreeTrialExpiredNoCreditCard:
							this.router.navigate(['authentication/payment']);
							break;
						case UserStateEnum.BillPendingRestrictedAccess:
							// no definition
							this.router.navigate(['user-management/billing-and-payment']);
							break;
						case UserStateEnum.BillPendingUnrestrictedAccess:
							this.router.navigate(['auth-landing']);
							break;
						default:
							this.toastNotificationService.sendErrorToast('Something went wrong! Please log in again');
							localStorage.removeItem('details');
							this.authenticationService.logout();
					}
				})
			),
		{ dispatch: false }
	);
	logInFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<LogInFailure>(AuthenticationActionType.LogInFailure),
				tap(() => this.toastNotificationService.sendErrorToast('Invalid email and password combination'))
			),
		{ dispatch: false }
	);

	private bigCommerceReloadAction(): void {
		this.launchHandler = setTimeout(() => {
			this.router.navigate(['accounts']);
			window.open(window.location.origin + '/accounts', '_blank').focus();
			this.matDialog.closeAll();
		}, 10000);
		this.matDialog
			.open<GenericPopupComponent, PopUpInterface>(GenericPopupComponent, {
				data: {
					message: 'Your Filed dashboard will launch in the next tab in 10 seconds, please allow popup from www.filed.com',
					actionAnswer: 'Launch now',
					noActionAnswer: '',
					header: 'Setup Installation Successful',
					image: '/assets/icons/success.svg'
				},
				panelClass: ['generic-popup'],
				disableClose: true
			})
			.afterClosed()
			.subscribe(clicked => {
				if (clicked) {
					clearTimeout(this.launchHandler);
					this.router.navigate(['accounts']);
					window.open(window.location.origin + '/accounts', '_blank').focus();
					this.matDialog.closeAll();
				}
			});
	}
}
